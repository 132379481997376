import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { AsyncAjaxGet, AsyncAjaxPost, AsyncAjaxPut } from '../component/Common/AsyncAjaxServiceV2';
import { utils, DateFormate, ConvertUtcToLocal, DateFormateFomChat, isAndroidIOSTabIpad, HandleHistory, StoreLocalConversation, LinkWithTooltip } from '../utils/Utils';
import { useChatContext } from './Store/ChatProvider';
import { useDispatch, useSelector } from 'react-redux';
import useGenerateToken from '../component/Hooks/useGenerateToken';
import { LeftControlNames, MobileCallBackAction, PopupTypes, SelectionControlTypeEnum, VagaroConnectMessageType, middleScrollHeight } from './Binding/ChatBoatEnums';
import { unmountComponentAtNode } from 'react-dom';
import SuccessAlert from '../component/Common/SuccessAlert';
import ImageGallery from '../component/Common/ImageGallery';
import { StenCiledConvertions } from './Binding/StenciedData';
import { ConnectionStatus } from './Binding/ChatBoatEnums';
import ReactDOM from 'react-dom';
import { Groupicon, Usericon } from './CommonComponent/Icons';
import MultiGroupSelectEmployee from '../component/Controls/MultiGroupSelectEmployee';
import MerchantsSingleSelecttwo from '../component/Controls/MerchantsSingleSelecttwo';
import Dropdown from 'react-bootstrap/Dropdown';
import $ from "jquery";
import VideoPlayer from './CommonComponent/VideoPlayer';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { CheckTypingUser } from '../component/Slice/TypingUser';
import { selectTypingUsers } from '../component/Selector/CustomSelector';
import { useSwipeable } from 'react-swipeable';
import process from 'process';


const ChatArea = (props) => {
    const { chatSelectionID, chatSelection, ChatboadSelection, connection, connectionStatus, setConnectionStatus, CommonMethod, AddCommonMethod, storeEmployeeData, ScrollisBottom, Addtextmarketing, smsavail, setAIControl, AIControl } = useChatContext();
    const {
        merchantId,
        EditInput,
        ReactBusinessName,
        ReactFirstName,
        ReactLastName,
        ReactProfileImage,
        ReactCdnUrl,
        messageConvertions,
        setMessageConvertions,
        pageIndex,
        setPageIndex,
        tempChatData,
        setTempChatData,
        LoginUserIdHash,
        BtnRightsidepenal,
        rightsidePenal,
        isNewChat,
        LeftOption,
        ViewEmployee,
        ViewCustomer,
        ExpandMinimizeButtonClick,
        expandMinimizeShow,
        convertionData,
        isCustomerChat,
        UserFromConversation,
        setUserFromConversation,
        chatAreaShow,
        setChatAreaShow,
        ReactBusinessImage,
        ModifyCustomer,
        ModifyEmployee,
        setConvertionData,
        setShowFirstLoader,
        middleArea,
        ReactUserID,
        searchText,
        setsearchText,
        ManageCustomerViewRights,
        LoginUserIDV2,
        ChatBoxCloseClick,
        isFromConnectApp,
        setIsViewChanging,
        isViewChanging,
        UpsertChannel,
        customerData,
        isAndroidIOSMobile,
        isIOSMobile,
        isMultilocationBusiness,
        CheckLoginUserIsRemovedFromChat,
        device,
        pushNotificationMessageId,
        setConversetionChatSelection,
        employeeGroupDetails, ManageCustomerModifyRights,
        setnewChatSelectedEmployee,
        countryID,
        RenderMentiontag,
        CustomerId,
        defaultSize,
        employeeNewChat,
        isLeftOption,
        isFromMySiteRef,
        setTempConvertionData
    } = props;
    const [restrictPaging, setRestrictPaging] = useState(true);
    const [holdFuctiontimer, setHoldFuctiontimer] = useState(null);
    const [imageGallery, setImageGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState(false);
    const [showEditDeleteAction, setshowEditDeleteAction] = useState(null);
    const [showCopyDownloadLink, setshowCopyDownloadLink] = useState(null);
    const [showEditEditor, setshowEditEditor] = useState(null);
    const [showReactionAction, setshowReactionAction] = useState(null);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [showLongPress, setShowLongPress] = useState(false);
    const timeoutRef = useRef(null);
    const [lastTouchEnd, setLastTouchEnd] = useState(0);
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    let isIpadold = /iPad/i.test(navigator.userAgent);
    let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
    let vagaroapp = navigator.userAgent.toLowerCase().indexOf("vagaroapp") > -1
    const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
    let Custommerapp = navigator.userAgent.includes('com.vagaro.customerios') || navigator.userAgent.includes('com.vagaro.customerandroid')
    //const middleArea = useRef(null);
    const dispatch = useDispatch();
    const [renderState, renderPage] = useState(false);
    const isLoadedData = useRef(false);
    const StoreScrollHeight = useRef(0);
    const heightScroll = useRef(0);
    const EditMessageScroll = useRef(false);
    let orientationQuery = window.matchMedia('(orientation: portrait)');

    const VagaroAPIEnum = {
        UploadImage: `connect/${merchantId}/sendfile`,
        TextSend: `${merchantId}/VagaroConnect/text/send`,
        SMSStatus: `connect/${merchantId}/messages/sms/status`,
        LikeDislikeMessage: `connect/VagaroConnectChatController/dislikeaimessage`,
    }


    const doRender = () => {
        renderPage(!renderState);
    }

    const removeDuplicateMessages = (data) => {
        const uniqueMessages = {};
        data.forEach(conversation => {

            if (!uniqueMessages[conversation.messageId]) {
                uniqueMessages[conversation.messageId] = conversation;
            }
        });
        const filteredConversations = Object.values(uniqueMessages);
        return filteredConversations;
    };


    const addEmoji = (emojiObject) => {
        if (emojiObject.unified !== undefined) {
            const reactionType = emojiObject.name === 'Thumbs Up'
                ? 'Like'
                : emojiObject.name === 'Red Heart'
                    ? 'Heart'
                    : emojiObject.name;

            const emojiObj = {
                reactionType: reactionType,
                unified: emojiObject.unified,
                reaction: emojiObject.native
            };
            setShowEmojis(!showEmojis);
            return emojiObj;
        }
        else {
            setShowEmojis(!showEmojis);
        }
    };
    const handleEmojiSelect = (message, index, messages, emoji) => {
        EditDeleteMessage(message, 6, index === messages.length - 1, addEmoji(emoji));
        // setShowPicker(false); 
    };

    const MemberIsexists = chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true;

    const mergeChatData = (chatData, searchPageIndex) => {
        var mergedObject = chatData;
        if (tempChatData !== undefined && tempChatData !== null && tempChatData.length > 0 && !UserFromConversation) {
            if (searchPageIndex !== undefined) {
                mergedObject = tempChatData.concat(mergedObject);
            } else {
                mergedObject = mergedObject.concat(tempChatData);
            }
        }
        setUserFromConversation(false);
        const sortedMessages = mergedObject.sort((a, b) => new Date(a.messageDate) - new Date(b.messageDate));
        // debugger
        //if user blocked and msg sent
        // mergedObject = mergedObject.filter((d) => d.messageId !== props.sendResponse?.messageId)

        mergedObject = removeDuplicateMessages(sortedMessages);

        const groupedMessages = mergedObject.reduce((acc, message) => {
            const date = moment(message.messageDate).format('YYYY-MM-DD');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(message);
            return acc;
        }, {});
        setTempChatData(mergedObject);
        setMessageConvertions(groupedMessages);
    }

    const GetCustomerMessageConvertion = async (chatSelectionParam, searchPageIndex, isScroll = false) => {
        isLoadedData.current = false;
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ViewCustomer === false) || ("Employee" === LeftOption && ViewEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            let chatSection = chatSelectionParam.ConversationId === undefined || chatSelectionParam.ConversationId === null || chatSelectionParam.ConversationId === "" ? chatSelectionParam : chatSelectionParam.ConversationId
            let userId = "";
            let apiPageIndex = 1;
            if (chatSelectionParam.Type === "Customer") {
                userId = chatSelectionParam.Id;
            }
            if (isCustomerChat) {
                userId = LoginUserIDV2;
            }
            const MessageId = (chatSelectionParam.AllData !== undefined && chatSelectionParam.AllData !== null && chatSection.AllData !== "") ? chatSelectionParam.AllData.messageId === undefined || chatSelectionParam.AllData.messageId === null || chatSelectionParam.AllData.messageId === "" ? "" : chatSelectionParam.AllData.messageId : ""
            if (isCustomerChat) {
                window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
            }
            if (searchPageIndex !== undefined) {
                apiPageIndex = searchPageIndex;
            } else {
                apiPageIndex = pageIndex;
            }
            await AsyncAjaxGet(
                "connect/VagaroConnectChat/conversations/detail?ConversationId=" + chatSection + "&PageIndex=" + apiPageIndex + "&PageSize=20&MessageId=" + MessageId + "&SearchKey=" + searchText.trim() + "&LoggedInUserId=" + LoginUserIDV2 + "&UserId=" + userId + "&MerchantId=" + merchantId,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            if (isCustomerChat) {
                                if (data.data.data.conversationId !== undefined && data.data.data.conversationId !== null) {
                                    chatSelection.IsBlocked = data.data.data.isBlocked;
                                    chatSelection.AllData.isBlocked = data.data.data.isBlocked;
                                }
                            }
                            if (!isCustomerChat) {
                                if ((searchText.length > 0 || MessageId !== "") && data.data.data.pageIndex > 1) {
                                    chatSelection.AllData.pageIndex = data.data.data.pageIndex; // Temp
                                    setRestrictPaging(false);
                                    // setisRestrictPageIndex(true);
                                    isLoadedData.current = false;
                                    setPageIndex(chatSelection.AllData.pageIndex);
                                }
                            }
                            if (data.data.data.conversations.length > 0) {
                                if (data.data.data.conversations.length < 10) {
                                    setRestrictPaging(false);
                                } else {
                                    setRestrictPaging(true);
                                    if (searchPageIndex === undefined && isScroll === false) {
                                        middleArea.current.scrollTop = 30;
                                    }
                                }
                                var messageData = data.data.data.conversations;
                                if (isCustomerChat && data.data.data.isBlocked === true) {
                                    var blockMessage = {
                                        message: `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`,
                                        isDeleted: false,
                                        isGroupUpdateMessage: true,
                                        messageDate: new Date()
                                    }
                                    messageData.push(blockMessage)
                                }
                                // set isCustomerOnline value before sending message to customer 
                                chatSelection.AllData.isCustomerOnline = data.data.data.isCustomerOnline;
                                if (data.data.data.isBlocked === true) {
                                    chatSelection.IsBlocked = data.data.data.isBlocked;
                                    chatSelection.AllData.isBlocked = data.data.data.isBlocked;
                                }
                                mergeChatData(messageData, searchPageIndex);
                                setIsViewChanging(false);
                                setShowFirstLoader(false);
                                isLoadedData.current = true;
                            } else {
                                setRestrictPaging(false);
                                isLoadedData.current = false;
                                if (isCustomerChat) {
                                    window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                                }
                            }
                            if (!isCustomerChat && isLeftOption === LeftControlNames.Customer) {
                                setAIControl(data.data.data?.isTakeControl ? data.data.data.isTakeControl : false);
                            }
                            window.PrevChnageContainerClose = true;
                        }
                        else {
                            setRestrictPaging(false);
                            isLoadedData.current = false;
                            if (isCustomerChat) {
                                window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                            }
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Conversation Detail",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setRestrictPaging(false);
                        isLoadedData.current = false;
                        if (isCustomerChat) {
                            window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                        }
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Conversation Detail",
                            "",
                            error
                        );
                    }
                },
                function OnError(data) {
                    setRestrictPaging(false);
                    isLoadedData.current = false;
                    if (isCustomerChat) {
                        window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                    }
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Conversation Detail",
                        "",
                        data
                    );
                },
                true
            );
        }
    }

    useEffect(() => {
        const handleInputFocus = () => {
            let CheckEdit = document.getElementsByClassName('ckeditor_2')
            if (middleArea.current !== null && isFromConnectApp && CheckEdit.length === 0) {
                let windowHeight = $(window).height();
                let VisualHeight = window.visualViewport.height;
                setTimeout(() => {
                    if (windowHeight !== VisualHeight) {
                        if (middleArea.current?.scrollTop && middleArea.current.scrollTop === heightScroll.current) {
                            if (StoreScrollHeight.current === 0) {
                                middleArea.current.scrollTop = heightScroll.current + VisualHeight + windowHeight;
                            } else {
                                middleArea.current.scrollTop = StoreScrollHeight.current + VisualHeight + middleScrollHeight;
                            }
                        }
                        else {
                            if (ScrollisBottom.current) {
                                middleArea.current.scrollTop = StoreScrollHeight.current + VisualHeight + middleScrollHeight;
                            } else {
                                //middleArea.current.scrollTop = StoreScrollHeight.current;
                            }
                        }
                    }
                }, 250);
            }
        };
        window.visualViewport.addEventListener('resize', () => {
            handleInputFocus()
        });
        document.body.addEventListener('focusin', handleInputFocus);
        return () => {
            window.visualViewport.removeEventListener('resize', handleInputFocus);
            document.body.removeEventListener('focusin', handleInputFocus);
        };
    }, []);

    const UploadImageRequest = (conversationId, src, fileName) => {
        const RequestData = {};
        RequestData.ConversationId = conversationId;
        RequestData.FileContent = src;
        RequestData.UploadFileName = fileName;
        return RequestData;
    }

    const ImageUploadOnDrive = async (src, fileName, onComplete, onfailer) => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.UploadImage,
            UploadImageRequest(chatSelection.ConversationId, src, fileName),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (onComplete) {
                            onComplete(data);
                        }
                        // return data.data.data;
                    }
                    else {
                        onfailer(data);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Upload Image",
                            UploadImageRequest(chatSelection.ConversationId, src, fileName),
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    onfailer(data);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Upload Image",
                        UploadImageRequest(chatSelection.ConversationId, src, fileName),
                        error
                    );
                }
            },
            function OnError(data) {
                onfailer(data);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Upload Image",
                    UploadImageRequest(chatSelection.ConversationId, src, fileName),
                    data
                );
            },
            true
        );
    }

    const RemoveImageBase64 = async (SendData, onSuccess) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(SendData, 'text/html');

        const images = doc.querySelectorAll('img');
        if (images.length > 0) {
            let count = 0;
            images.forEach(async (img) => {
                const alt = img.getAttribute('alt');
                const src = img.getAttribute('src');
                if ((alt !== 'Gif' && alt !== 'VagaroDrive') && (src && src.startsWith('data:image'))) {
                    const src = img.getAttribute('src');
                    await ImageUploadOnDrive(src, alt, data => {
                        img.setAttribute('src', data.data.data);
                        img.setAttribute('alt', alt);
                        count++;
                        if (count === images.length) {
                            const updatedHtmlString = doc.body.innerHTML;
                            onSuccess(updatedHtmlString);
                        }
                    }, error => { return "" });
                }
                else {
                    count++;
                    if (count === images.length) {
                        const updatedHtmlString = doc.body.innerHTML;
                        onSuccess(updatedHtmlString);
                    }
                }
            })
        }
        else {
            const updatedHtmlString = doc.body.innerHTML;
            onSuccess(updatedHtmlString);
        }
    }

    const EditMessagesRequest = async (RequestObject, isReaction, ResponseRequestData) => {
        await RemoveImageBase64(RequestObject.message, ResponseMessage => {
            if (ResponseMessage !== false) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(ResponseMessage, 'text/html');
                const images = doc.querySelectorAll('img');
                const RequestData = {};
                RequestData.attachments = [];
                if (images.length > 0) {
                    images.forEach(img => {
                        const FileUrl = img.getAttribute('src');
                        const FileName = img.getAttribute('alt');
                        RequestData.attachments.push({ FileUrl, FileName });
                    });
                }
                if (RequestObject.attachment) {
                    RequestObject.attachment.forEach(arr =>
                        RequestData.attachments.push(arr)
                    )
                }
                var modifiedHtmlString = ResponseMessage.replace(/(<img\b[^>]*>)\s*(<br>|&nbsp;)*\s*(?=<img\b)/gi, '$1');
                RequestData.messageId = RequestObject.messageId;
                RequestData.conversationId = chatSelection.ConversationId;
                RequestData.senderId = RequestObject.sentBy;
                RequestData.message = modifiedHtmlString;
                RequestData.isnote = RequestObject.isNote;
                RequestData.action = "Edit";
                RequestData.SentByHash = isCustomerChat ? LoginUserIdHash : chatSelection.AllData.customerIdHash;
                RequestData.messageDate = RequestObject.messageDate;
                RequestData.chanelId = LeftOption === LeftControlNames.Customer ? chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId : props.MerchantChannelId.toString();
                RequestData.reactions = isReaction ? RequestObject.updatedReaction : [];
                ResponseRequestData(RequestData);
            }
        });
    }

    const TextSMS = async (RequestData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPost(
                VagaroAPIEnum.TextSend,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null && data.data.data.errorType === 0) {
                            const textData = data.data.data;
                            var percentage = ((textData.usage * 100) / textData.planUsageLimit).toFixed(2);
                            var variant = "success";
                            if (percentage >= 0 && percentage <= 90) {
                                variant = "success";
                            }
                            else if (percentage > 90 && percentage < 100) {
                                variant = "warning";
                            }
                            else if (percentage >= 100) {
                                variant = "danger";
                            }
                            Addtextmarketing({ ...textData, percentage: percentage, variant: variant });
                            let statusRequest = {};
                            statusRequest.SMSStatus = 1;
                            statusRequest.IsSMS = true;
                            statusRequest.MessageId = RequestData.MessageId;
                            statusRequest.ActiveSMSForMessenger = textData.activeSMSForMessenger;
                            statusRequest.FromTextMessengerPhone = textData.fromTextMessengerPhone;
                            statusRequest.Usage = textData.usage;
                            statusRequest.MessengerUsage = textData.messengerUsage;
                            statusRequest.PlanUsageLimit = textData.planUsageLimit;
                            statusRequest.OverLimitRateInCentsText = textData.overLimitRateInCentsText;
                            statusRequest.PlanName = textData.planName;
                            statusRequest.NumberStatus = textData.numberStatus;
                            SMSStatus(statusRequest);
                            window.parent.postMessage('{"eventType": "ReloadTextMessagingPlanIframe","Data": ""}', '*');
                        }
                        else {

                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Text SMS",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Text SMS",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Text SMS",
                        RequestData,
                        data
                    );
                },
            );
        }
    }

    const SMSStatus = async (RequestData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPut(
                VagaroAPIEnum.SMSStatus,
                accesstoken,
                RequestData,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {

                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "SMS Status",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "SMS Status",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "SMS Status",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }

    const EditMessages = async (messageData, isReaction) => {
        const access_token = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            setshowEditDeleteAction(null);
            setshowCopyDownloadLink(null);
            setshowEditEditor(null);
            setShowLongPress(false);
            setshowReactionAction(null);
            await EditMessagesRequest(messageData, isReaction, async Response => {
                const requestData = {
                    ...Response,  // actionType: actionType // Add actionType to the request payload                  
                };
                await AsyncAjaxPut(
                    "connect/" + merchantId + "/editmessage",
                    access_token,
                    requestData,
                    function onSuccess(data) {
                        try {
                            if (data != null && data.data != null && data.data.data != null) {

                                var sendMsgRequestData = {};
                                var msgResponse = data.data.data;
                                sendMsgRequestData.messageId = msgResponse.id;
                                sendMsgRequestData.conversationId = msgResponse.conversationId;
                                sendMsgRequestData.SenderId = msgResponse.senderId;
                                sendMsgRequestData.fullname = msgResponse.fullName;
                                sendMsgRequestData.message = msgResponse.message;
                                sendMsgRequestData.MemberIds = msgResponse.MemberIds;
                                sendMsgRequestData.status = msgResponse.status;
                                sendMsgRequestData.isnote = msgResponse.isNote;
                                sendMsgRequestData.attachment = msgResponse.attachment;
                                sendMsgRequestData.isMessageFromCustomer = msgResponse.isMessageFromCustomer
                                sendMsgRequestData.isDeleted = false;
                                sendMsgRequestData.isGroupUpdateMessage = false;
                                sendMsgRequestData.SentByHash = msgResponse.senderIdHash;
                                sendMsgRequestData.IsRemove = false;
                                sendMsgRequestData.reactions = msgResponse.reactions;
                                window.setEditMessage(sendMsgRequestData);
                                if (msgResponse.isMessageFromCustomer && msgResponse.isCustomerOnline === false && !isCustomerChat) {

                                    const allMessages = Object.values(messageConvertions).flat();
                                    const customerMessages = allMessages.filter(
                                        message => message.isMessageFromCustomer
                                    );
                                    const lastMessage = customerMessages[customerMessages.length - 1];
                                    const isLastMessage = lastMessage && lastMessage.messageId === msgResponse.id


                                    var SendSMSRequest = {};
                                    let fileUrls = "";
                                    if (msgResponse?.attachments?.length > 0) {
                                        fileUrls = msgResponse.attachments.map(attachment => attachment.FileUrl);
                                    }
                                    SendSMSRequest.Message = isLastMessage ? Response.reactions[0].reaction : Response.reactions[0].reaction + " - " + msgResponse.message + fileUrls;
                                    SendSMSRequest.MessageId = msgResponse.id;
                                    SendSMSRequest.IsMessageFromCustomer = false;
                                    SendSMSRequest.IsCustomerOnline = false;
                                    SendSMSRequest.ReceiverId = (isNewChat || props.newChat) ? CustomerId.current : chatSelection.AllData.customerId;
                                    SendSMSRequest.LoggedInUserId = LoginUserIDV2;
                                    SendSMSRequest.Attachments = msgResponse.attachments;
                                    SendSMSRequest.IsVcard = false;
                                    if (smsavail.current) {
                                        TextSMS(SendSMSRequest);
                                    }
                                }

                            }
                            else {
                                utils.appInsightTrack(
                                    props.ReactBusinessId,
                                    props.ReactUserID,
                                    "Edit Message",
                                    Response,
                                    data != null && data.data != null ? data.data : ""
                                );
                            }
                        } catch (error) {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Edit Message",
                                Response,
                                error
                            );

                        }
                    },
                    function OnError(data) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Edit Message",
                            Response,
                            data
                        );

                    },
                    true
                );

            });
        }
    }

    const DeleteUndoMessageRequest = (requestdata) => {
        const RequestData = {};
        RequestData.MessageId = requestdata.messageId;
        RequestData.ConversationId = chatSelection.ConversationId;
        RequestData.chanelId = LeftOption === LeftControlNames.Customer ? chatSelection.AllData.chanelId : props.MerchantChannelId.toString();
        RequestData.SenderId = props.LoginUserIDV2;
        return RequestData;
    }

    const DeleteUndoMessage = async (messageData) => {
        const access_token = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPut(
                `connect/${merchantId}/message/${messageData.isDeleted ? "Delete" : "UndoDelete"}`,
                access_token,
                DeleteUndoMessageRequest(messageData),
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            const sentRequestData = {};
                            sentRequestData.messageId = messageData.messageId;
                            sentRequestData.conversationId = chatSelection.ConversationId;
                            sentRequestData.senderId = messageData.sentBy;
                            sentRequestData.message = messageData.message;
                            sentRequestData.isnote = messageData.isNote;
                            sentRequestData.action = messageData.isDeleted ? "Delete" : "UndoDelete";
                            sentRequestData.isDeleted = messageData.isDeleted ? true : false;
                            sentRequestData.SentByHash = isCustomerChat ? LoginUserIdHash : chatSelection.AllData.customerIdHash;
                            sentRequestData.messageDate = messageData.messageDate;
                            sentRequestData.isMessageFromCustomer = messageData.isMessageFromCustomer
                            if (isFromConnectApp) {
                                handleOutsideClick();
                            }
                            // if (connection.state === 'Connected') {
                            //     if (LeftOption === LeftControlNames.Customer) {
                            //         //business to customer / customer to business
                            //         connection.invoke('SendGroup', sentRequestData.SentByHash + window.ReactBusinessId, sentRequestData);
                            //     } else {
                            //         //employee to employee 
                            //         connection.invoke('SendGroup', window.ReactBusinessId + "", sentRequestData);
                            //     }
                            // }
                            doRender();
                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Delete Undo-Delete Message",
                                DeleteUndoMessageRequest(messageData),
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Delete Undo-Delete Message",
                            DeleteUndoMessageRequest(messageData),
                            error
                        );

                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Delete Undo-Delete Message",
                        DeleteUndoMessageRequest(messageData),
                        data
                    );

                },
                true
            );
        }
    }


    window.callBackChatConversation = (response) => {
        response.senderFirstName = ReactFirstName;
        response.senderLastName = ReactLastName;
        response.isSMS = response.isNote === false && chatSelection.AllData.isCustomerOnline === false && "Customers" === ActiveLeftOption ? true : false
        setMessageConvertions(prevState => {
            const updatedChatData = { ...prevState };
            const dateKey = moment(response.messageDate).format('YYYY-MM-DD');
            if (updatedChatData[dateKey]) {
                updatedChatData[dateKey].push(response);
            } else {
                updatedChatData[dateKey] = [response];
            }
            return updatedChatData;
        });
        if (middleArea.current !== null) {
            middleArea.current.scrollTop = middleArea.current?.scrollHeight;
            StoreScrollHeight.current = middleArea.current.scrollTop;
            ScrollisBottom.current = true;
        }
    }

    window.setMessageIdIntoMessageObject = (tempMsgData, sendMessageobject) => {
        if (tempMsgData.conversationId === chatSelection.ConversationId) {
            const dateKey = moment(tempMsgData.messageDate).format('YYYY-MM-DD');
            setMessageConvertions(prevState => {
                if (prevState[dateKey]) {
                    return {
                        ...prevState,
                        [dateKey]: prevState[dateKey].map((x) => {
                            if (x.tempMessageId === tempMsgData.tempMessageId && sendMessageobject.isGroupUpdateMessage === false) {
                                if (sendMessageobject?.attachment?.length > 0) {
                                    return {
                                        ...x,
                                        message: sendMessageobject.message,
                                        messageId: sendMessageobject.messageId,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName,
                                        attachment: sendMessageobject.attachment,

                                    };
                                }
                                else {
                                    return {
                                        ...x,
                                        messageId: sendMessageobject.messageId,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName,
                                        attachment: sendMessageobject.attachment,

                                    };
                                }
                            }
                            else {
                                if (x.sentByHash === LoginUserIdHash && sendMessageobject.isGroupUpdateMessage === false) {
                                    return {
                                        ...x,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName,
                                    };
                                }
                            }
                            return x;
                        })
                    };
                }
                return prevState;
            });
        }
        if (!isCustomerChat) {
            const updatedData = convertionData.map(d =>
                d.conversationId === tempMsgData.conversationId
                    ? { ...d, isCustomerOnline: sendMessageobject.sendMessageobject, messageId: sendMessageobject.messageId, isDeleted: false, lastMessageDate: new Date(), lastMessage: tempMsgData.message, attachment: sendMessageobject.attachment, customerIdHash: chatSelection.AllData.isGroupChat ? tempMsgData.SentByHash : d.customerIdHash }
                    : d
            );
            setTempConvertionData(updatedData);
            setConvertionData(updatedData)
        }
    }
    const hasUserReacted = (message, unified) => {
        if (message.reactions !== undefined && message.reactions !== null && message.reactions !== "") {
            return message.reactions.some(
                (reaction) => reaction.unified === unified && reaction.reacted.includes(LoginUserIdHash)
            );
        }
    };

    window.setEditMessage = (tempMsgData) => {
        if (tempMsgData.conversationId === chatSelection.ConversationId) {
            const dateKey = moment(tempMsgData.messageDate).format('YYYY-MM-DD');
            setMessageConvertions(prevState => {
                if (prevState[dateKey]) {
                    return {
                        ...prevState,
                        [dateKey]: prevState[dateKey].map((x) => {
                            if (x.messageId === tempMsgData.messageId) {
                                return {
                                    ...x,
                                    messageId: tempMsgData.messageId,
                                    message: tempMsgData.message,
                                    attachment: tempMsgData.attachment
                                };
                            }
                            return x;
                        })
                    };
                }
                return prevState;
            });
        }
        if (!isCustomerChat) {
            const updatedData = convertionData.map(d =>
                d.conversationId === tempMsgData.conversationId
                    ? { ...d, messageId: tempMsgData.messageId, isDeleted: false }
                    : d
            );
            setConvertionData(updatedData)
        }
    }

    useEffect(() => {
        EditMessageScroll.current = false;
        if (expandMinimizeShow !== 3) {
            if (isFromConnectApp && orientationQuery.matches === false) {
                setTimeout(() => {
                    window.GetConversationById();
                }, 100);
            } else {
                window.GetConversationById();
            }
        }
    }, [chatSelectionID]);



    window.GetConversationById = () => {
        if (chatSelection.ConversationId !== "") {
            if (!isCustomerChat && (chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined)) {
                if (LeftOption === LeftControlNames.Customer) {
                    if (connection.state === 'Connected') {
                        var sendGroupData = {
                            "messageType": VagaroConnectMessageType.ChannelSubscriptionMessage,
                            "message": 'BUSINESS_JOINED'
                        }
                        connection.invoke('JoinGroup', chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "", sendGroupData.message);
                        connection.invoke('SendGroup', props.ReactBusinessId.toString() + "", sendGroupData);
                    }
                } else {
                    chatSelection.AllData.chanelId = props.MerchantChannelId.toString();
                }
            }
            if (!isCustomerChat) {
                //console.log("Upsert channel for Chat selection")
                UpsertChannel(chatSelection.AllData.customerId, chatSelection?.AllData?.customerIdHash, isFromConnectApp ? true : false);
            }
            setshowEditEditor(null);
            setshowEditDeleteAction(null);
            setshowCopyDownloadLink(null);
            setshowReactionAction(null);
            setShowLongPress(false);
            GetCustomerMessageConvertion(chatSelection);
            // if (connection.state === 'Connected') {
            //     if (!isCustomerChat) {
            //         window.JoinToGroupBusiness(connection, chatSelection.AllData.customerIdHash);
            //     }
            // }

        }
        if (middleArea.current !== null) {
            middleArea.current.scrollTop = middleArea.current?.scrollHeight;
        }
        // utils.SetGroupNameWidth();
        AddCommonMethod("MessageConvertionRender", () => { GetCustomerMessageConvertion(chatSelectionID); })
        AddCommonMethod("ConvertionDetailRender", () => { GetCustomerMessageConvertion(chatSelection, undefined, true); })
    }

    // useEffect(() => {
    //     console.log(connectionStatus);
    //     if (connectionStatus != null && connectionStatus === 'Connected' && convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
    //         //            window.JoinToAllConversations(connection)
    //         //console.log("business has started to connect all conversations");

    //         convertionData.forEach(async (data) => {
    //             window.JoinToGroupBusiness(connection, data.customerIdHash);
    //         })

    //         //console.log("business has connected to all conversations");
    //     }
    // }, [connectionStatus, convertionData]);

    // function connectWindowSizeMessageHeader() {
    //     utils.SetGroupNameWidth();
    // }
    // window.onresize = connectWindowSizeMessageHeader;

    window.JoinToGroupBusinessReconnect = (connection) => {

        //console.log("business has started to reconnect all conversations");
        if (convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
            convertionData.forEach(async (data) => {
                window.JoinToGroupBusiness(connection, data.customerIdHash);
            })
        }
        //console.log("business has reconnected");

    };
    window.JoinToAllConversations = (connection) => {

        //console.log("business has started to connect all conversations");
        if (convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
            convertionData.forEach(async (data) => {
                window.JoinToGroupBusiness(connection, data.customerIdHash);
            })
        }
        //console.log("business has connected to all conversations");

    };
    //useEffect(() => {
    //        console.log("business");
    //        if (connection) {               

    //            connection.onreconnected(() => {
    //                setConnectionStatus(ConnectionStatus.Connected);
    //                console.log('Reconnected Business');
    //                window.JoinToGroupBusiness(connection, chatSelection.AllData.customerIdHash);
    //            });


    //        }


    //}, [connection]);

    useEffect(() => {
        if (((pageIndex !== chatSelection.AllData.pageIndex) || (pageIndex > 1 && restrictPaging === true)) && !props.newChat && expandMinimizeShow !== 3) {
            if (chatSelection.ConversationId !== undefined && chatSelection.ConversationId !== null && chatSelection.ConversationId !== "") {
                GetCustomerMessageConvertion(chatSelection.ConversationId);
                if ((isIpad || isIpadold || isTablet) && isFromConnectApp) {
                    CommonMethod.TriggerClick(".addMemberModal .btn-close");
                    CommonMethod.RemoveClassbyClassName("employeeDetailPophoverWrap.show", "show")
                    if (rightsidePenal.current?.className && rightsidePenal.current.className === "rightSlide rightSlide-open") {
                        rightsidePenal.current.className = "rightSlide rightSlide-close";
                    }
                }
            }
        }
    }, [pageIndex]);

    window.EditOnUpArrow = () => {
        const ckEditor = document.getElementsByClassName("ckeditor_2");
        if (ckEditor.length === 0 && !isNewChat && !props.newChat) {
            if (messageConvertions !== undefined && messageConvertions !== null && messageConvertions !== "") {
                const allMessages = Object.values(messageConvertions).flat();
                if (allMessages.length > 0) {
                    for (let index = 1; index < allMessages.length; index++) {
                        const lastMessage = allMessages[allMessages.length - index];
                        if (lastMessage?.sentByHash === LoginUserIdHash && !lastMessage.isDeleted && lastMessage.isVcard === false && lastMessage?.isGroupUpdateMessage === false && lastMessage?.isAIMessage === false) {
                            if (lastMessage.messageId !== undefined && lastMessage.messageId !== "") {
                                EditDeleteMessage(lastMessage, 1);
                                let messageElement = document.getElementById(lastMessage.messageId);
                                if (messageElement) {
                                    setTimeout(() => {
                                        messageElement.scrollIntoView({ behavior: 'auto', block: 'end' });
                                    }, 200);
                                }
                            }
                        }
                        if (!lastMessage?.isGroupUpdateMessage) {
                            break;
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (middleArea.current !== null && middleArea.current.scrollTop === 0 && pageIndex === 1 && !props.newChat && searchText.length < 3 && EditMessageScroll.current === false) {
            setTimeout(() => {
                if (middleArea?.current) {
                    middleArea.current.scrollTop = middleArea.current.scrollHeight;
                    heightScroll.current = middleArea.current.scrollTop;
                }
            }, 150);
        }
        if (messageConvertions !== undefined && messageConvertions !== null && messageConvertions !== "" && EditMessageScroll.current === false) {
            if (chatSelection.AllData !== undefined && chatSelection.AllData !== null && chatSelection.AllData !== "") {
                var messageId = "";
                if (searchText.length > 0 && props.conClickScroll.current) {
                    messageId = chatSelection.AllData.messageId;
                } else if (isAndroidIOSMobile && pushNotificationMessageId && pushNotificationMessageId?.current !== "" && chatSelection.AllData.pageIndex !== undefined) {
                    messageId = pushNotificationMessageId.current;
                }

                if (messageId !== undefined && messageId !== "") {
                    let messageElement = document.getElementById(messageId);
                    if (messageElement) {
                        setTimeout(() => {
                            messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            props.conClickScroll.current = false;
                            pushNotificationMessageId.current = "";
                        }, 200);
                    }
                }
                else {
                    if (searchText.length < 3 && pageIndex === 1) {
                        const imageElements = document.querySelectorAll('.messages img');
                        let loadedImages = 0;

                        const handleImageLoad = () => {
                            loadedImages++;
                            if (loadedImages < 8) {
                                if (middleArea.current !== undefined && middleArea.current !== null) {
                                    middleArea.current.scrollTop = middleArea.current?.scrollHeight;
                                    heightScroll.current = middleArea.current.scrollTop;
                                }
                            }
                        };

                        if (imageElements.length === 0) {
                            if (middleArea.current !== undefined && middleArea.current !== null) {
                                middleArea.current.scrollTop = middleArea.current?.scrollHeight;
                                heightScroll.current = middleArea.current.scrollTop;
                            }
                        }

                        imageElements.forEach(image => {
                            image.addEventListener('load', handleImageLoad);
                        });

                        return () => {
                            imageElements.forEach(image => {
                                image.removeEventListener('load', handleImageLoad);
                            });
                        };
                    }
                }
            }
        }

    }, [messageConvertions])

    const onClickBackButtonFromChatArea = () => {
        if (isFromConnectApp && employeeGroupDetails?.current && employeeGroupDetails.current !== "") {
            setConversetionChatSelection(employeeGroupDetails.current, true);
            CommonMethod.Actionclick("messagesAreaGroupDetail")
            employeeGroupDetails.current = "";
        } else {
            setnewChatSelectedEmployee("");
            setChatAreaShow(false);
            ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
        }
        if (isFromConnectApp === false && expandMinimizeShow === 2) {
            StoreLocalConversation(0);
        }
        window.DeleteChannelDetail(false);
        var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'ifream-chatActive' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
    }
    const handleCopyLink = (event, url, ChangeClass = false) => {
        navigator.clipboard.writeText(url).then(() => {
            //Link copied to clipboard!
            Object.values(document.getElementsByClassName("fa-regular fa-circle-check")).map(val => val.className = "fa-solid fa-link-simple");
            if (event && ChangeClass) {
                if (event.target.tagName === 'I') {
                    event.target.className = 'fa-regular fa-circle-check';
                } else {
                    event.target.childNodes[0].className = 'fa-regular fa-circle-check';
                }
            }

        }).catch(err => {
            //'Failed to copy link.'

            console.error('Could not copy text: ', err);
        });
    };
    const handleCopyMessageText = (e, text, attachment = [], ChangeClass = false) => {
        try {
            const tempDiv = document.createElement("div");
            if (text) {
                tempDiv.innerHTML = `<p>${text}</p>`;
            }
            for (const file of attachment) {
                if (file.type.startsWith('video/')) {
                    tempDiv.innerHTML += `
                        <video controls width="300">
                            <source src="${file.fileUrl}" type="${file.type}">
                            Your browser does not support the video tag.
                        </video>
                    `;
                } else if (file.type.startsWith('image/')) {
                    tempDiv.innerHTML += `<img src="${file.fileUrl}" alt="${file.fileName}" />`;
                }
            }
            utils.CallBackGivenToMobile(MobileCallBackAction.CopyText, tempDiv.textContent)
            // document.body.appendChild(tempDiv);
            // const range = document.createRange();
            // range.selectNodeContents(tempDiv);
            // const selection = window.getSelection();
            // selection.removeAllRanges();
            // selection.addRange(range);
            // document.execCommand('copy');
            // document.body.removeChild(tempDiv);
        } catch (error) {
            console.error("Failed to copy content:", error);
        }
    };

    const handleDownload = async (event, URL, Name) => {
        event.preventDefault();
        const link = document.createElement('a');
        if (isFromConnectApp || vagaroapp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.DownlodVideo, URL);
        } else {
            const response = await fetch(URL);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            link.href = encodeURI(url);
            link.download = Name || 'video.mp4';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    const ShowDesignMessage = (message, messageObject) => {
        const imageRegex = /<img[^>]+src="([^">]+)"[^>]*alt="((?:(?!Gif)[^"])*)"[^>]*>/g;
        const imageMatches = message.match(imageRegex) || [];
        const ShowImages = imageMatches.slice(0, 9);
        const otherContent = message.replace(imageRegex, '').replace(/<p[^>]*>(\s*<br\s*\/?>\s*)*<\/p>/g, '');
        const parser = new DOMParser();
        const otherContentElements = parser.parseFromString(otherContent, 'text/html').body.childNodes;
        const elements = [];
        const Videos = (messageObject?.attachment || []).filter((val) => val.type && val.type.includes("video"));
        otherContentElements.forEach((element, idx) => {
            if (element.tagName !== undefined && element.tagName !== null && element.tagName !== "") {
                if (element.tagName.toLowerCase() === 'p') {
                    const anchorTags = element.getElementsByTagName('a');
                    const imgTags = element.getElementsByTagName('img');
                    const vgUserTags = element.getElementsByTagName('vg-user');
                    const newDiv = document.createElement('div');
                    if (anchorTags.length === 0 && imgTags.length === 0) {
                        const contentHTML = element.innerHTML;
                        const modifiedHTML = contentHTML.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" style="cursor: pointer;">$1</a>');
                        element.innerHTML = modifiedHTML;
                    }
                    for (let i = 0; i < anchorTags.length; i++) {
                        anchorTags[i].setAttribute('target', Custommerapp ? '_top' : '_blank');
                        anchorTags[i].style.cursor = 'pointer';
                        if (isFromConnectApp) {
                            newDiv.innerHTML = anchorTags[i].innerHTML;
                            newDiv.style.cursor = 'pointer';
                            newDiv.className = 'mobileURlLink';
                            newDiv.dataset.LinkTarget = anchorTags[i].href || anchorTags[i].innerHTML;
                            newDiv.style.textDecoration = "underline"
                            anchorTags[i].replaceWith(newDiv);
                        } else {
                            if (anchorTags[i].href?.toLowerCase().includes("/connect" || "/connectai") && isCustomerChat === true && Custommerapp === false) {
                                if (!isFromMySiteRef.current) {
                                    anchorTags[i].setAttribute('target', '_top');
                                }
                                let url = new URL(anchorTags[i].href);
                                url.searchParams.set('opencustomerchat', 'true');
                                anchorTags[i].href = url.toString();
                            }
                        }
                    }
                    for (let i = 0; i < vgUserTags.length; i++) {
                        const vgUser = vgUserTags[i];
                        vgUserTags[i].dataset.id = vgUser.getAttribute('id')
                        vgUserTags[i].dataset.name = vgUserTags[i].innerText
                        vgUserTags[i].className = 'mentionNameInmessage';
                        // if ( vgUserTags[i].dataset.id == LoginUserIdHash) {
                        //     vgUser.classList.add('additionalClassName');
                        // }
                    }
                }
            }

            let htmlContent = element.outerHTML === undefined ? message : element.outerHTML;
            const pattern = /<br>(&nbsp;)+/g;
            const cleanedHtml = htmlContent.replace(pattern, '');
            elements.push(<div id={messageObject.messageId} key={`other_${idx}`} dangerouslySetInnerHTML={{ __html: cleanedHtml }} />);
        });

        if (imageMatches.length > 0) {
            elements.push(
                <div className='image-wrap multiple' data-image={ShowImages.length} onClick={(e) => ChatIamgeClick(message, e)} onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && showLongPress === false && e.target.click() }}>
                    {ShowImages.map((imgTag, idx) => (
                        <div key={idx} className="imgBlock">
                            <img style={{ cursor: 'pointer' }} src={imgTag.match(/src="([^"]+)"/)[1]} alt={imgTag.match(/alt="([^"]+)"/) ? imgTag.match(/alt="([^"]+)"/)[1] : `Image ${idx + 1}`} />
                            {imageMatches.length > 8 && idx === 8 &&
                                <div className={idx === 8 && "moreImgText"}>
                                    {idx === 8 && imageMatches.length - 9 > 0 && <>+{imageMatches.length - 9}</>}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            );
        }
        if (Videos && Videos.length > 0) {
            elements.push(
                <div className={Videos.length > 1 ? 'videoWrapInmessage multipal' : 'videoWrapInmessage'} data-Video={Videos.length} >
                    {Videos.map(({ fileUrl, fileName, type }, idx) => (
                        <div className='firstPlaywrap'>
                            <VideoPlayer
                                key={idx}
                                URL={fileUrl}
                                Type={type}
                                Preview={Videos.length === 1 ? true : false}
                                Connectapp={isFromConnectApp}
                                isIOSMobile={isIOSMobile}
                                isAndroidIOSMobile={isAndroidIOSMobile}
                            />
                            <div className='video-footer' onMouseLeave={() => {
                                Object.values(document.getElementsByClassName("fa-regular fa-circle-check")).map(val => val.className = "fa-solid fa-link-simple");
                            }}>

                                <span className='videoFileName'>
                                    <i className='fa-sharp fa-regular fa-circle-play'></i>
                                    <span className='videoFileNameBlock'>
                                        {fileName}
                                    </span>
                                </span>
                                <div className='copyUrlWrap'>
                                    {/* <LinkWithTooltip tooltip="Copy link" placement = "top" isFromConnectApp={isFromConnectApp}>
                                        <button onClick={(e) => handleCopyLink(e, fileUrl, true)} className='videoControl videoControl-copyUrl'>
                                            <i class="fa-solid fa-link-simple"></i>
                                        </button>
                                    </LinkWithTooltip> */}
                                    <LinkWithTooltip tooltip="More attachments options" placement="top" isFromConnectApp={isFromConnectApp}>
                                        <Dropdown className="videoDropdown mobile-contextmenu" onToggle={(toggle) => {
                                            if (toggle) {
                                                CommonMethod.AddClass(`#${messageObject.messageId}`, "videooptionDropOpen")
                                                CommonMethod.AddClassToBody("videooptionDropOpenBody")

                                            } else {
                                                CommonMethod.RemoveClassbyClass("videooptionDropOpen")
                                                CommonMethod.RemoveClassToBody("videooptionDropOpenBody")
                                            }
                                        }}>
                                            <Dropdown.Toggle variant="">
                                                <i class="fa-regular fa-ellipsis"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item className='downloadcopy-file' onClick={(e) => { handleCopyLink(e, fileUrl) }}>
                                                        <i class="fa-solid fa-link-simple"></i> Copy Link
                                                    </Dropdown.Item> */}
                                                <Dropdown.Item className='downloadcopy-file' onClick={(e) => { handleDownload(e, fileUrl, fileName) }}>
                                                    <i class="fa-solid fa-download"></i> Download
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </LinkWithTooltip>
                                    {/* {isAndroidIOSMobile === false ?
                                    <></>
                                        :
                                        <button className='videoDropoptionButton' onClick={() => {
                                            setshowCopyDownloadLink({ name: fileName, url: fileUrl })
                                        }} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }} >
                                            <i class="fa-regular fa-ellipsis"></i>
                                        </button>
                                    } */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
        RenderMentiontag && RenderMentiontag()
        return elements;
    }
    const extractVgUserIds = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const vgUsers = doc.getElementsByTagName('vg-user');
        return Array.from(vgUsers).map(vgUser => vgUser.getAttribute('id'));
    };

    const showMentionSymbol = (message) => {
        const vgUserIds = extractVgUserIds(message);
        if (vgUserIds == "1") {
            return true;
        }
        return vgUserIds.includes(LoginUserIdHash);
    };
    const ChatIamgeClick = (imageMatches, event) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(imageMatches, "text/html");
        const imgTags = doc.querySelectorAll("img");
        var srcValues = [];
        imgTags.forEach(img => {
            const alt = img.getAttribute("alt")
            if (alt !== "Gif") {
                srcValues.push({ original: img.getAttribute("src"), thumbnail: img.getAttribute("src"), name: img.getAttribute("alt") });
            }
        });
        const index = srcValues.findIndex(item => item.original === event?.target?.src);
        if (index !== -1) {
            const sortedArray = srcValues.slice(index);
            srcValues = sortedArray.concat(srcValues.slice(0, index));
        }
        setGalleryImages(srcValues);
        if (isCustomerChat) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpen' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
            var jsonData = JSON.stringify({ IframName: 'body', className: 'vc-iframeGalleryOpen-parentBody' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
        }
        if (expandMinimizeShow === 2) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpenMini' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');

        }
        setImageGallery(true);
        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("btnCloseImageGallary") })
        if (isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.ImageGallary, { NativeAction: 4, photoUrlList: srcValues, customername: "", selectedPosition: 0 })
        }

    }
    const ChatwithEmployee = (d) => {
        var employee = convertionData.filter((val) => (val.customerIdHash === d.sentByHash && val.isGroupChat === false));
        if (isAndroidIOSMobile) {
            if (employee.length === 0) {
                setTimeout(() => { setChatAreaShow(false) }, 200);
            }
        }
        if (employee.length > 0) {
            if (isFromConnectApp && isAndroidIOSMobile) {
                CommonMethod.Actionclick("rightMobileHeaderBack");
            }
            setConversetionChatSelection(employee[0], true)
        } else {
            employeeNewChat(d.sentByHash || "");
        }
    }
    const EditDeleteMessage = (messageData, action, isLastMessageIndex, reaction, AIResponce = true) => {
        if (!CheckModifyRights() && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
            return;
        }
        if (chatSelection.IsBlocked === true) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={`You Don't Have a permission to Send Message`}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );

            if (isCustomerChat) {
                return ReactDOM.render(
                    <Fragment>
                        <SuccessAlert
                            alertType={"warning"}
                            strMessage={`${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`}
                            displaytime={2000}
                        />
                    </Fragment>,
                    document.getElementById("OpenDialog1")
                );
            } else {
                utils.ShowAlert("Alert", "You Don't Have a permission to Send Message", "Close");
                return;
            }
        }
        if (action === 1) { //Edit
            setshowEditDeleteAction(null);
            setShowLongPress(false);
            setshowReactionAction(null);
            setshowEditEditor(messageData);
        } else if (action === 2 || action === 5) { //Delete/Undo Delete
            EditMessageScroll.current = true;
            messageData.isDeleted = action === 2 ? true : false;
            if (!isCustomerChat && convertionData.filter((last) => last.conversationId === chatSelection.ConversationId && isLastMessageIndex !== undefined && isLastMessageIndex == true).length > 0) {
                const updatedData = convertionData.map(d =>
                    d.conversationId === chatSelection.ConversationId && isLastMessageIndex == true
                        ? { ...d, lastMessage: action === 2 ? "This message was deleted" : messageData.message }
                        : d
                );
                setConvertionData(updatedData);
            }
            setshowEditDeleteAction(null);
            setshowCopyDownloadLink(null);
            setshowEditEditor(null);
            setShowLongPress(false);
            DeleteUndoMessage(messageData);
            setshowReactionAction(null);
        } else if (action === 3) { //Save
            EditMessageScroll.current = true;
            // setshowEditDeleteAction(null);
            // setshowEditEditor(null);            
            if (!isCustomerChat && convertionData.filter((last) => last.conversationId === chatSelection.ConversationId && isLastMessageIndex !== undefined && isLastMessageIndex == true).length > 0) {
                const updatedData = convertionData.map(d =>
                    d.conversationId === chatSelection.ConversationId && isLastMessageIndex == true
                        ? { ...d, lastMessage: messageData.message }
                        : d
                );
                setConvertionData(updatedData);
            }
            if (messageData.isEdited === true) {
                EditMessages(messageData, false);
            }
        } else if (action === 4) { //Cancel
            EditMessageScroll.current = true;
            setshowEditDeleteAction(null);
            setshowCopyDownloadLink(null);
            setshowEditEditor(null);
            setShowLongPress(false);
            setshowReactionAction(null);
        } else if (action === 6) {
            // if (messageData.isMessageFromCustomer === true && smsavail.current === false && chatSelection?.AllData?.isCustomerOnline === false) {
            //     return; This is For textmarketing not Condition
            // }
            if (MemberIsexists === false) {

                // const updateReactions = (message, newReaction) => {
                //     if (!message.reactions) {
                //         message.reactions = [];
                //     }

                //     let existingReactionIndex = -1;
                //     let previousReactionIndex = -1;

                //     // Iterate over the reactions to find if the user has already reacted or if the reaction type exists
                //     message.reactions.forEach((reaction, index) => {
                //         if (reaction.reacted && reaction.reacted.includes(newReaction.LoginUserIdHash)) {
                //             previousReactionIndex = index; // Track the index of the previous reaction by the user
                //         }
                //         if (reaction.unified === newReaction.unified) {
                //             existingReactionIndex = index; // Track the index of the reaction type the user wants to add
                //         }
                //     });

                //     // Handle removal of previous reaction
                //     if (previousReactionIndex !== -1) {
                //         // User has reacted previously, so remove their old reaction
                //         const prevReaction = message.reactions[previousReactionIndex];
                //         if (prevReaction.reacted) {
                //             prevReaction.reacted = prevReaction.reacted.filter(id => id !== newReaction.LoginUserIdHash);
                //             prevReaction.count -= 1;

                //             // If the count of the old reaction becomes 0, remove it from the array
                //             if (prevReaction.count === 0) {
                //                 message.reactions.splice(previousReactionIndex, 1);
                //                 // Adjust existingReactionIndex if necessary
                //                 if (existingReactionIndex > previousReactionIndex) {
                //                     existingReactionIndex -= 1;
                //                 }
                //             }
                //         }

                //         // If the user is removing the same type of reaction they previously added, return the updated message
                //         if (existingReactionIndex === previousReactionIndex) {
                //             return message;
                //         }
                //     }

                //     // Handle addition of new reaction
                //     if (existingReactionIndex !== -1) {
                //         // If the new reaction type already exists, increment its count and add the user ID
                //         const existingReaction = message.reactions[existingReactionIndex];
                //         if (existingReaction && existingReaction.reacted && !existingReaction.reacted.includes(newReaction.LoginUserIdHash)) {
                //             existingReaction.reacted.push(newReaction.LoginUserIdHash);
                //             existingReaction.count += 1;
                //         }
                //     } else {
                //         // If the new reaction type does not exist, add it to the reactions array
                //         message.reactions.push({
                //             reactionType: newReaction.reactionType,
                //             count: 1,
                //             date: new Date().toISOString(),
                //             unified: newReaction.unified,
                //             reacted: [newReaction.LoginUserIdHash],
                //             reaction: newReaction.reaction,
                //             reactedId: props.LoginUserIDV2
                //         });
                //     }

                //     return message;
                // };

                // const MessageConvertions = (prevState, response, newReaction) => {
                //     const updatedChatData = { ...prevState };
                //     const dateKey = moment(response.messageDate).format('YYYY-MM-DD');

                //     const reactionWithMetadata = {
                //         ...newReaction,
                //         date: new Date(),
                //         LoginUserIdHash: props.LoginUserIdHash,
                //         reactedId: props.LoginUserIDV2
                //     };

                //     if (updatedChatData[dateKey]) {
                //         const messageIndex = updatedChatData[dateKey].findIndex(
                //             (msg) => msg.messageId === response.messageId
                //         );

                //         if (messageIndex !== -1) {
                //             updatedChatData[dateKey][messageIndex] = updateReactions(
                //                 updatedChatData[dateKey][messageIndex],
                //                 reactionWithMetadata
                //             );
                //         } else {
                //             response = updateReactions(response, reactionWithMetadata);
                //             updatedChatData[dateKey].push(response);
                //         }
                //     }
                //     else {
                //         response = updateReactions(response, reactionWithMetadata);
                //         updatedChatData[dateKey] = [response];
                //     }

                //     return updatedChatData;
                // };

                // setMessageConvertions(prevState => MessageConvertions(prevState, messageData, reaction));

                if (!messageData.updatedReaction) {
                    messageData.updatedReaction = [];
                }
                if (messageData.isVcard === true) {
                    return;
                }
                const updatedReaction = {
                    reactionType: reaction.reactionType,
                    count: 1,
                    date: new Date().toISOString(),
                    unified: reaction.unified,
                    reaction: reaction.reaction,
                    reacted: [
                        props.LoginUserIdHash
                    ],
                    reactedId: props.LoginUserIDV2
                };
                messageData.updatedReaction = [];
                messageData.updatedReaction.push(updatedReaction);
                EditMessageScroll.current = true;
                EditMessages(messageData, true);

            }
        } else if (action === 7) { // massage
            ChatwithEmployee(messageData)
        } else if (action === 8) { // Undo responce
            EditMessageScroll.current = true;
            LikeDislikeMessage(messageData);
            setMessageConvertions(prevState => {
                const updatedChatData = { ...prevState };
                const dateKey = moment(messageData.messageDate).format('YYYY-MM-DD');
                if (updatedChatData[dateKey]) {
                    updatedChatData[dateKey] = updatedChatData[dateKey].map(arr => arr.messageId === messageData.messageId ? { ...arr, isDislikeMessage: !AIResponce } : arr)
                }
                return updatedChatData;
            });
        }
    }

    const GroupchatIcon = (memberId) => {
        const Membicon = chatSelection.AllData.members.filter(item => item.memberIdHash === memberId);
        if (Membicon.length > 0) {
            return (
                <Fragment>
                    {Membicon[0].photo !== undefined ?
                        < img alt='avtar' className="avatar-group-member" src={Membicon[0].cdnUrl + 'Original/' + Membicon[0].photo} />
                        : <div> {utils.setLetter(Membicon[0].firstName || "", Membicon[0].lastName || "")}</div>
                    }
                </Fragment>
            )
        } else {
            return
        }
    }

    const CheckModifyRights = () => {
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            return false;
        } else {
            return true;
        }
    }
    const ReactionDropDown = ({ message, iconId, children, data, handleEmojiSelect }) => {
        const [view, setview] = useState(false);

        const CalculateHeight = () => {
            let checkIedit = document.getElementById(`editControlmainWrap_${iconId}`);
            if (checkIedit !== undefined && checkIedit !== "" && checkIedit !== null) {
                var dropdownTop = $(`#editControlmainWrap_${iconId}`).offset().top + $(`#editControlmainWrap_${iconId}`).outerHeight();
                var windowHeight = $(window).height();
                var dropdownHeight = $(`.Emoji${iconId}`).outerHeight();
                if (windowHeight - dropdownTop < dropdownHeight) {
                    $(`.Emoji${iconId}`).css("top", $(`#editControlmainWrap_${iconId}`).offset().top - dropdownHeight);
                    $(`.Emoji${iconId}`).css("left", $(`#editControlmainWrap_${iconId}`).offset().left);
                    $(`.Emoji${iconId}`).css("position", "fixed");
                    $(`.Emoji${iconId}`).css("bottom", "inherit");
                    $(`.Emoji${iconId}`).css("transition", "none");
                    $(`.Emoji${iconId}`).css("animation", "none");
                    $(`.Emoji${iconId}`).css("z-index", "99");


                } else {
                    if (dropdownHeight !== undefined && dropdownHeight !== null && dropdownHeight !== "") {
                        $(`.Emoji${iconId}`).css("top", $(`#editControlmainWrap_${iconId}`).offset().top + $(`#editControlmainWrap_${iconId}`).outerHeight());
                        $(`.Emoji${iconId}`).css("left", $(`#editControlmainWrap_${iconId}`).offset().left);
                        $(`.Emoji${iconId}`).css("position", "fixed");
                        $(`.Emoji${iconId}`).css("bottom", "inherit");
                        $(`.Emoji${iconId}`).css("transition", "none");
                        $(`.Emoji${iconId}`).css("animation", "none");
                        $(`.Emoji${iconId}`).css("z-index", "99");
                    }
                }
            }
        };

        const OpenRootReaction = () => {
            if (isAndroidIOSMobile && isFromConnectApp) {
                unmountComponentAtNode(document.getElementById("root_reaction_emojiPicker"));
                ReactDOM.render(
                    <MobileEmojiPicker message={message} />,
                    document.getElementById("root_reaction_emojiPicker")
                )
            }
        };

        useEffect(() => {
            CalculateHeight()
        }, [view]);


        if (isAndroidIOSMobile === false || (defaultSize === 1 && isCustomerChat)) {
            return (
                <>
                    <a href={false} className='editControl reactEmojiWrap fa-regular fa-face-smile-plus' onClick={() => setview(true)} onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && e.target.click() }}></a>
                    {(isAndroidIOSMobile === false || (defaultSize === 1 && isCustomerChat)) &&
                        <div
                            style={{ display: view ? "block" : "none" }}
                            className={`Emoji${iconId} EmojiPickerReact`}
                        >
                            <Picker data={data} onEmojiSelect={(e) => handleEmojiSelect(e)} autoFocus={isAndroidIOSTabIpad() ? false : isAndroidIOSMobile ? false : true} navPosition={"bottom"} previewPosition={"none"} skinTonePosition={"none"} perLine={"8"} maxFrequentRows={"2"} theme={isCustomerChat ? "light" : document.querySelector('html').getAttribute('data-theme') === 'light' ? "light" : "dark"} />
                        </div>
                    }
                </>
            );
        } else {
            return (
                <a
                    href={false}
                    className='editControl reactEmojiWrap fa-regular fa-face-smile-plus'
                    onClick={(e) => { OpenRootReaction() }}
                    onTouchStart={(e) => {
                        if (isIOSMobile || (device || "").includes('iosDevice')) {
                            OpenRootReaction();
                            e.preventDefault();
                        }
                    }}
                >
                </a>
            );
        }
    }
    const MobileEmojiPicker = ({ message }) => {
        const [swipedUp, setSwipedUp] = useState(false);
        const [height, setHeight] = useState(350);
        const picker_Root = document.getElementById('root_reaction_emojiPicker')

        const ClosePicker = useCallback(
            () => {
                if (picker_Root) {
                    picker_Root.classList.remove('active');
                    picker_Root.style.height = '';
                }
                handleOutsideClick();
                unmountComponentAtNode(document.getElementById("root_reaction_emojiPicker"));
            },
            [picker_Root],
        );

        const handlers = useSwipeable({
            onSwipedUp: () => {
                setSwipedUp(true);
                setHeight(window.innerHeight - 43);
            },
            onSwipedDown: (eventData) => {
                if (eventData.deltaY > 300) {
                    setSwipedUp(false);
                    setHeight(350)
                }
            },
            onSwiping: (eventData) => {
                const newHeight = Math.max(350, Math.min(window.innerHeight - 43, height - eventData.deltaY));
                if (eventData.dir === 'Up') {
                    setHeight(newHeight);
                }
            },
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        });

        const handleEmojiSelect = (e) => {
            EditDeleteMessage(message, 6, null, addEmoji(e));
            ClosePicker()
        };

        const handleClickOutside = useCallback(
            (event) => {
                const Picker = document.getElementById('emoji_picker_Wrap');
                if (Picker && !Picker.contains(event.target)) {
                    ClosePicker();
                }
            },
            [ClosePicker],
        );
        const handleSearchClick = () => {
            setSwipedUp(true);
            setHeight(window.innerHeight - 43);
        };
        const handleSearchInput = () => {
            process.nextTick(() => {
                const picker_Selection = picker_Root.getElementsByTagName('em-emoji-picker')[0]?.component?.base
                if (picker_Selection && isAndroidIOSMobile) {
                    const searchInput = picker_Selection.querySelector('input');
                    if (searchInput) {
                        searchInput.addEventListener('click', handleSearchClick);
                    }
                    return () => {
                        if (searchInput) {
                            searchInput.removeEventListener('click', handleSearchClick);
                        }
                    };
                }
            })
        };

        useEffect(() => {
            if (picker_Root) {
                handleSearchInput()
                picker_Root.classList.add('active')
                picker_Root.style.height = '100%'
                const previousHandler = picker_Root.onclick;
                picker_Root.onclick = null;

                const clickHandler = (e) => handleClickOutside(e);
                picker_Root.addEventListener("click", clickHandler);

                return () => {
                    picker_Root.removeEventListener("click", clickHandler);
                    picker_Root.onclick = previousHandler;
                };
            }
        }, [picker_Root, handleClickOutside]);



        return (
            <div id='emoji_picker_Wrap' className={`emojiWrap ${swipedUp ? 'swipeUp' : 'swipeDown'}`}
                {...handlers} style={{ height: `${height}px` }}>
                <Picker dynamicWidth='true' data={data} onEmojiSelect={(e) => { handleEmojiSelect(e); (isFromConnectApp || vagaroapp) && utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, ""); }} autoFocus={isAndroidIOSTabIpad() ? false : isAndroidIOSMobile ? false : true} navPosition={isAndroidIOSMobile ? "top" : "bottom"} previewPosition={"none"} skinTonePosition={"none"} perLine={"8"} maxFrequentRows={"2"} theme={isCustomerChat ? "light" : document.querySelector('html').getAttribute('data-theme') === 'light' ? "light" : "dark"} />
            </div>
        );
    };
    const MobileDetailsDropdown = () => {

        var data = convertionData.find(val => val.conversationId === chatSelection.AllData.conversationId);
        var ConID = 0;
        var pin = false
        var messageId = 0;
        var read = true;
        var block = false;
        var mute = false;
        var customerHashId = "";
        if (data) {

            ConID = data.conversationId;
            pin = data.isPin
            messageId = data.messageId;
            read = data.isRead;
            block = data.isBlocked;
            mute = data.isMute;
            customerHashId = data.customerIdHash;
            return (
                <Dropdown className="mobile-contextmenu">
                    <Dropdown.Toggle variant="">
                        <i class="fa-regular fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    {chatSelection.Type === LeftControlNames.Customer && <Dropdown.Menu>
                        {isAndroidIOSMobile &&
                            <Dropdown.Item href={false} onClick={(e) => {
                                // utils.CallBackGivenToMobile(MobileCallBackAction.CustomerProfile, { "customerId": data.customerId })
                                // utils.UnderDevelopment();
                                CommonMethod.Actionclick("messagesAreaGroupDetail")
                            }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                        }
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}>  <i class="fa-regular fa-thumbtack"></i> {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { block ? CommonMethod.BlockConvertion(PopupTypes.UnBlockUser, data) : CommonMethod.BlockConvertion(PopupTypes.BlockUser, data) }}> <i class="fa-regular fa-circle-xmark"></i> {block ? "Unblock User" : "Block User"}</Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }} className='remove-chat-red'> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                    </Dropdown.Menu>}
                    {chatSelection.Type === LeftControlNames.Employee && chatSelection.AllData.isGroupChat === false && <Dropdown.Menu>
                        {isAndroidIOSMobile &&
                            <Dropdown.Item href={false} onClick={(e) => {
                                // utils.CallBackGivenToMobile(MobileCallBackAction.EmployeeProfile, { "employeeId": data.customerId })
                                // utils.UnderDevelopment();
                                CommonMethod.Actionclick("messagesAreaGroupDetail")
                            }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                        }
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}> <i class="fa-regular fa-thumbtack"></i> {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                        {/* <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MuteNotification(LoginUserIDV2, ConID, mute ) }}>{mute ? "Unmute Notification":"Mute Notification"}</Dropdown.Item> */}
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }}> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                    </Dropdown.Menu>}
                    {chatSelection.Type === LeftControlNames.Employee && chatSelection.AllData.isGroupChat === true &&
                        <Dropdown.Menu>
                            {isAndroidIOSMobile &&
                                <Dropdown.Item href={false} onClick={() => { CommonMethod.Actionclick("messagesAreaGroupDetail") }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                            }
                            {/* <Dropdown.Item href={false} onClick={() => { CommonMethod.Actionclick("messagesAreaGroupDetail"); CommonMethod.Actionclick("divAddMember"); AddCommonMethod("MobileCallBackForBack", ()=>{ CommonMethod.Actionclick("rightMobileHeaderBack");}) }}> <i class="fa-regular fa-user-plus"></i>  Add Members</Dropdown.Item> */}
                            {/* <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MuteNotification(LoginUserIDV2, ConID, mute) }}>{mute ? "Unmute Notification" : "Mute Notification"}</Dropdown.Item> */}
                            <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}> <i class="fa-regular fa-thumbtack"></i>  {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                            {CheckLoginUserIsRemovedFromChat(data) === false &&
                                <>
                                    <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                                    <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.LeaveChat, ConID); }}> <i class="fa-regular fa-arrow-right-from-bracket"></i> Leave Chat</Dropdown.Item>
                                </>}
                            <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }}> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                        </Dropdown.Menu>}
                </Dropdown>
            );
        } else {
            return <></>;
        }
    };

    const handleOutsideClick = (e) => {
        setshowEditDeleteAction(null);
        setshowReactionAction(null);
        setshowCopyDownloadLink(null);
        setShowLongPress(false);
    };
    const handleOutsideClickEvent = (e) => {
        if (isAndroidIOSMobile && !['svg', 'path'].includes(e?.target?.tagName) && !['videoDropoptionButton', 'fa-regular fa-ellipsis', 'fa-regular fa-face-smile-plus'].includes(e.target.className)) {
            setshowCopyDownloadLink(null);
        }
    };

    const handleScrollEvent = (e) => {
        let isAtBottom = e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - (isAndroidIOSMobile ? 5 : 30);
        let isAtTop = e.target.scrollTop === 0;
        StoreScrollHeight.current = e.target.scrollTop;
        ScrollisBottom.current = isAtBottom;
        if (isLoadedData.current === true && restrictPaging === true) {
            if (!isAtTop && chatSelection.AllData.pageIndex !== undefined && chatSelection.AllData.pageIndex !== 0
                && isAtBottom && chatSelection.AllData.pageIndex > 0) {
                // api call with chatSelection.AllData.pageIndex - 1
                chatSelection.AllData.pageIndex -= 1;
                if (chatSelection.AllData.pageIndex > 0) {
                    GetCustomerMessageConvertion(chatSelection.ConversationId, chatSelection.AllData.pageIndex);
                }
            } else if (isAtTop && restrictPaging === true && e.target.scrollHeight > e.target.clientHeight) {
                setPageIndex(pageIndex + 1);
            }
        }
        if (isFromConnectApp === true && isAndroidIOSMobile === true) {
            // setshowEditDeleteAction(null)
            // setShowLongPress(false);
            setshowCopyDownloadLink(null)
            if (window.location.href.includes("employeedetailsActive") && isAndroidIOSTabIpad()) {
                CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails();
            }
        }
        if (window.onScrollCloseItems) window.onScrollCloseItems();
    }

    //long press message

    const handleTouchStart = () => {
        const ckEditor = document.getElementsByClassName("ckeditor_2");
        if (ckEditor.length === 0) {
            timeoutRef.current = setTimeout(() => {
                setShowLongPress(true);
                if ((isFromConnectApp || vagaroapp) && !showLongPress) {
                    utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, "");
                }
            }, 500);
        }
    };

    const handleTouchEnd = (e, index, message, messages) => {
        const ckEditor = document.getElementsByClassName("ckeditor_2");
        if (ckEditor.length === 0) {
            const now = Date.now();
            if (now - lastTouchEnd <= 300) {
                handleGifDoubleClick(index, message, messages);
            }
            setLastTouchEnd(now);
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const handleGifDoubleClick = (index, message, messages) => {
        if (message.isAIMessage === false) {
            EditDeleteMessage(message, 6, index === messages.length - 1, { reactionType: 'Like', unified: '1f44d', reaction: '👍' })
            if (message.isVcard === false && (isFromConnectApp || vagaroapp)) {
                utils.CallBackGivenToMobile(MobileCallBackAction.DoVibrate, "");
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClickEvent);
        return () => { document.removeEventListener('click', handleOutsideClickEvent); };
    }, []);

    const Reacted = (props) => {
        const [showLongPress, setShowLongPress] = useState(false);
        const [selectedReaction, setSelectedReaction] = useState(null);
        const [hoveredReaction, setHoveredReaction] = useState(null);
        const [hoveredPlus, setHoveredPlus] = useState(false);
        const [reactionBeingLongPressed, setReactionBeingLongPressed] = useState(null);
        const [placement, setPlacement] = useState('bottom-end');
        const containerRef = useRef(null);
        const popoverRef = useRef(null);
        const ReactiontimeoutRef = useRef(null);
        var calculateHeight = null;
        const checkSpace = useCallback(
            () => {
                if (containerRef.current) {
                    const rect = containerRef.current.getBoundingClientRect();
                    const spaceAtBottom = window.innerHeight - rect.bottom;
                    const popoverHeight = document.getElementsByClassName('reactionPopHover')[0]?.offsetHeight;
                    if (popoverHeight) {
                        if (spaceAtBottom < popoverHeight) {
                            setPlacement('top-end');
                        } else {
                            setPlacement('bottom-end');
                        }
                    }
                }
            },
            [],
        );


        const handleTouchStart = (reactionType) => {
            if (isFromConnectApp || (isCustomerChat && isAndroidIOSMobile)) {
                ReactiontimeoutRef.current = setTimeout(() => {
                    // setTimeout(() => {
                    if (reactionType !== undefined && reactionType !== null && reactionType !== "") {
                        setShowLongPress(true);
                        setReactionBeingLongPressed(reactionType);
                        setHoveredPlus(false);
                    } else {
                        setReactionBeingLongPressed(null);
                        setHoveredPlus(true);
                        setShowLongPress(true);
                    }
                    // }, 500);
                    if (isFromConnectApp || vagaroapp) {
                        utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, "");
                    }
                }, 500);
            }
        };

        const handleTouchEnd = (reactionType) => {
            if (isFromConnectApp && ReactiontimeoutRef.current) {
                clearTimeout(ReactiontimeoutRef.current);
                ReactiontimeoutRef.current = null;
            }
            if (showLongPress && reactionBeingLongPressed === reactionType) {
                // setTimeout(() => {
                setShowLongPress(true);  // Keep the popover open
                // }, 100);
            } else {
                setShowLongPress(false);
                setReactionBeingLongPressed(null);
            }

        };

        const handlePlusTouchEnd = () => {
            // const holdTime = (Date.now() - holdStartTime) / 1000;
            // if (holdTime > 0.5) {
            //     setReactionBeingLongPressed(null);
            //     setHoveredPlus(true);
            //     setShowLongPress(true);
            //     if (navigator.vibrate) {
            //         navigator.vibrate(200);
            //     }
            // } else {
            //     setHoveredPlus(prev => !prev);
            // }
            // setHoldStartTime(0);
            if (ReactiontimeoutRef.current) {
                clearTimeout(ReactiontimeoutRef.current);
                ReactiontimeoutRef.current = null;
            }

        };

        const handleReactionClick = (index, message, reactionType) => {
            if (Array.isArray(message.reactions) && isCustomerChat === false) {
                const reaction = message.reactions.find(r => r.reactionType === reactionType);
                if (reaction && Array.isArray(reaction.reacted) && reaction.reacted.includes(LoginUserIdHash)) {
                    EditDeleteMessage(
                        message,
                        6,
                        index === message.length - 1,
                        { reactionType, unified: reaction.unified, reaction: reaction.reaction }
                    );
                }
            } else if (Array.isArray(message.reactions) && (isCustomerChat === true && !isAndroidIOSMobile)) {
                const reaction = message.reactions.find(r => r.reactionType === reactionType);
                if (reaction && Array.isArray(reaction.reacted) && reaction.reacted.includes(LoginUserIdHash)) {
                    EditDeleteMessage(
                        message,
                        6,
                        index === message.length - 1,
                        { reactionType, unified: reaction.unified, reaction: reaction.reaction }
                    );
                }
            }
        };

        const handleMouseLeave = () => {
            setShowLongPress(false);
            setHoveredReaction(null)
            setReactionBeingLongPressed(null);
            setHoveredPlus(false);
        };

        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setShowLongPress(false);
                setReactionBeingLongPressed(null);
                setHoveredPlus(false);
            }
        };

        const handleFocusOut = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setShowLongPress(false);
                setReactionBeingLongPressed(null);
                setHoveredPlus(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('focusin', handleFocusOut);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('focusin', handleFocusOut);
            };
        }, []);
        if (props) {
            const reactionCounts = props.reaction.reduce((acc, reaction) => {
                const reactedCount = Array.isArray(reaction.reacted) ? reaction.reacted.length : 0;

                if (reaction.reaction) {
                    acc[reaction.reaction] = {
                        reaction: reaction.reaction,
                        count: reactedCount,
                        reactionType: reaction.reactionType,
                        unified: reaction.unified,
                        reactionBlock: reaction,
                        messages: props.message,
                    };
                }
                return acc;
            }, {});

            const reactionEmployeeMap = getUserListFromReactions(props) || [];
            const reactionsToDisplay = Object.entries(reactionCounts).slice(0, 5);
            const extraReactions = Object.entries(reactionCounts).slice(5);

            const employee = (arr) => {
                var tempEmp = arr.employees.filter(val => val.memberIdHash === LoginUserIdHash);
                if (arr.employees.filter(val => val.memberIdHash !== LoginUserIdHash).length > 0) {
                    tempEmp.push({
                        "firstName": ReactBusinessName.split(" ")[0] || "",
                        "lastName": ReactBusinessName.split(" ")[1] || "",
                        "fullName": ReactBusinessName,
                        // "memberIdHash": "169081C17F36F761EFC9952E886237574151ED13",
                        "photo": ReactBusinessImage || null,
                        "cdnUrl": null,
                        // "reactionType": "Face with Tears of Joy"
                    })
                }
                return tempEmp;
            };
            const popover = (reactionType) => {
                if (reactionEmployeeMap) {
                    const employeesForReaction = reactionEmployeeMap.filter(employee => employee.reactionType === reactionType);
                    const reactionTypeMap = employeesForReaction.reduce((acc, employee) => {
                        if (!acc[employee.reactionType]) {
                            acc[employee.reactionType] = [];
                        }
                        acc[employee.reactionType].push(employee);
                        return acc;
                    }, {});
                    const uniqueReactionTypes = Object.keys(reactionTypeMap).map(reactionType => ({
                        reactionType,
                        employees: reactionTypeMap[reactionType]
                    }));
                    let uniqueReaction = []
                    if (isCustomerChat) {
                        uniqueReaction = uniqueReactionTypes.map(arr => (
                            {
                                employees: employee(arr),
                                reactionType: arr.reactionType
                            }
                        ));

                    } else {
                        uniqueReaction = uniqueReactionTypes;
                    }
                    clearTimeout(calculateHeight);
                    calculateHeight = setTimeout(() => { checkSpace() }, 100);
                    return (
                        <Popover className='reactionPopHover' onTouchStart={(e) => { e.stopPropagation(); }} onTouchEnd={(e) => { e.stopPropagation(); }}
                            onMouseEnter={() => { setHoveredReaction(reactionType); setHoveredPlus(false); }} onMouseLeave={handleMouseLeave}
                        >
                            {uniqueReaction.map((reactionGroup, index) => (
                                <div key={index} className='reactionContentWrap' ref={popoverRef}>
                                    <Popover.Header as="h3">
                                        {reactionGroup.reactionType}
                                    </Popover.Header>
                                    <Popover.Body>
                                        <div className='reactedPersonBlock' >
                                            {reactionGroup.employees.map((employee, empIndex) => (
                                                <div className='reactedPerson' key={empIndex}>
                                                    <div className="avatar avatar-sm reactedPerson-img" data-avatar="1">
                                                        <div className="avatar-group-img-wrap">
                                                            {employee.photo ? (
                                                                // <img alt='avatar' className="avatar-group-member" src={`${employee.cdnUrl}${employee.cdnUrl.endsWith('/') ? '' : '/'}Original/${employee.photo}`} />
                                                                <img alt='avatar' className='avatar-group-member' src={`${employee.cdnUrl ? `${employee.cdnUrl}${employee.cdnUrl.endsWith('/') ? '' : '/'}Original/` : ''}${employee.photo}`} />
                                                            ) : (
                                                                <div className='review-profile-inner-text vg-avatar-initial-inner-text'>{utils.setLetter(employee.firstName || "", employee.lastName || "")}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <h4 className='reactedPerson-name'>{employee.fullName}</h4>
                                                </div>
                                            ))}
                                        </div>
                                    </Popover.Body>
                                </div>
                            ))}
                        </Popover>
                    );
                } else {
                    return <></>;
                }
            };

            const plusPopover = (
                <Popover className='reactionPopHover'
                    onTouchStart={(e) => { e.stopPropagation(); }} onTouchEnd={(e) => { e.stopPropagation(); }}
                    onMouseEnter={() => { setHoveredPlus(true); setReactionBeingLongPressed(null); }}
                    onMouseLeave={() => setHoveredPlus(false)}
                >
                    <div className='reactionContentWrap' ref={popoverRef}>
                        <Popover.Header as="h3">More Reactions</Popover.Header>
                        <Popover.Body>
                            <div className='reactedPersonBlock'>
                                {extraReactions.flatMap(([emoji, item], index) => {
                                    const employeesForReaction = reactionEmployeeMap.filter(employee => employee.reactionType === item.reactionType);
                                    return employeesForReaction.map((employee, empIndex) => (

                                        <div key={empIndex} className='reactedPerson'>
                                            <div className="avatar avatar-sm reactedPerson-img" data-avatar="1">
                                                <div className="avatar-group-img-wrap">
                                                    {employee.photo ? (
                                                        <img alt='avatar' className="avatar-group-member" src={`${employee.cdnUrl}${employee.cdnUrl.endsWith('/') ? '' : '/'}Original/${employee.photo}`} />
                                                    ) : (
                                                        <div className='review-profile-inner-text vg-avatar-initial-inner-text'>{utils.setLetter(employee.firstName || "", employee.lastName || "")}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <h4 className='reactedPerson-name'>{employee.fullName}</h4>
                                            <span className='reactedPerson-emoji'>{emoji}</span>
                                        </div>

                                    ));
                                })}
                            </div>
                        </Popover.Body>
                    </div>
                </Popover>
            );
            return (
                <div className='reactionBlock' ref={containerRef}>
                    {reactionsToDisplay.map(([emoji, item], index) => {
                        const loginReaction = item.reactionBlock.reacted.includes(LoginUserIdHash);
                        return (
                            <OverlayTrigger
                                key={index}
                                trigger={['hover', 'focus']}
                                placement={placement}
                                overlay={popover(item.reactionType)}
                                show={isFromConnectApp || (isCustomerChat && isAndroidIOSMobile) ? showLongPress && reactionBeingLongPressed === item.reactionType : selectedReaction === emoji || hoveredReaction === item.reactionType}
                                onToggle={() => { setSelectedReaction(selectedReaction === emoji ? null : emoji); }}
                            >
                                <div
                                    className={`reaction ${loginReaction ? "active" : ""}`}
                                    onClick={(e) => {
                                        if (isFromConnectApp === false && isCustomerChat === false) {
                                            const reactionsArray = Array.isArray(item.messages.reactions) ? item.messages.reactions : [];
                                            const userReacted = reactionsArray.some(reaction =>
                                                reaction.reacted.includes(LoginUserIdHash) &&
                                                reaction.reactionType === item.reactionType
                                            );
                                            if (userReacted) {
                                                EditDeleteMessage(
                                                    item.messages,
                                                    6,
                                                    index === item.messages.length - 1,
                                                    { reactionType: item.reactionType, unified: item.unified, reaction: item.reaction }
                                                );
                                            }
                                        } else {
                                            if (!showLongPress) {
                                                handleReactionClick(index, item.messages, item.reactionType);
                                            }
                                        }
                                    }}
                                    onTouchStart={(e) => { e.stopPropagation(); handleTouchStart(item.reactionType); }}
                                    onTouchEnd={(e) => { e.stopPropagation(); handleTouchEnd(item.reactionType); }}
                                >
                                    <div className='reaction-inner'>
                                        <span className='reaction-emoji'>{item.reaction}</span>
                                        <div className='reaction-count'>{item.count}</div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        );
                    })}

                    <div>
                        {extraReactions.length > 0 && (
                            <div className='reaction plus' onTouchStart={(e) => { e.preventDefault(); handleTouchStart(); }} onTouchEnd={handlePlusTouchEnd} onMouseEnter={() => setHoveredPlus(true)} onMouseLeave={() => setHoveredPlus(false)}>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement={placement}
                                    overlay={plusPopover}
                                    show={isFromConnectApp ? showLongPress && hoveredPlus : hoveredPlus}
                                >
                                    <div
                                        className="reaction-count"
                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                        onTouchStart={(e) => { e.preventDefault(); e.stopPropagation(); handleTouchStart(null); }}
                                        onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleTouchEnd(null); }}
                                    >
                                        +{extraReactions.length}
                                    </div>
                                </OverlayTrigger>
                            </div>
                        )}
                    </div>
                </div>
            );
        } return null;
    };

    const LikeDislikeMessage = async (message) => {
        const accesstoken = await useGenerateToken(dispatch);
        const RequestData = {
            "MessageId": message.messageId,
            "IsDisLikeMessage": message.isDislikeMessage ? false : true,
        };
        if ((("Customer" === isLeftOption && ModifyCustomer === false) || ("Employee" === isLeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPost(
                VagaroAPIEnum.LikeDislikeMessage,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {

                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Dislikeai Message",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Dislikeai Message",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Dislikeai Message",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }

    const getUserListFromReactions = (reactedArray) => {
        const employees = storeEmployeeData.current;

        if (reactedArray !== undefined && reactedArray !== null && reactedArray !== "" && employees !== undefined && employees !== null && employees !== "") {
            const allReactedEmployees = reactedArray.reaction.reduce((acc, reaction) => {
                if (!Array.isArray(reaction.reacted) || !reaction.reactionType) {
                    return acc;
                }

                // Add employees from the reaction
                reaction.reacted.forEach(userId => {
                    const employee = employees.find(emp => emp.memberIdHash === userId);
                    if (employee && !acc.has(employee.memberIdHash)) {
                        acc.set(employee.memberIdHash, {
                            ...employee,
                            reactionType: reaction.reactionType
                        });
                    }
                });

                // Add the current logged-in user if it's a customer chat
                if (isCustomerChat) {
                    const customerIdHash = LoginUserIdHash;
                    if (reaction.reacted.includes(customerIdHash) && !acc.has(customerIdHash)) {
                        acc.set(customerIdHash, {
                            firstName: props.initialData.ReactFirstName,
                            lastName: props.initialData.ReactLastName,
                            fullName: props.initialData.ReactFirstName + ' ' + props.initialData.ReactLastName,
                            memberId: LoginUserIDV2,
                            memberIdHash: props.initialData.LoginUserIdHash,
                            photo: props.initialData.ReactProfileImage,
                            cdnUrl: props.initialData.ReactCdnUrl,
                            reactionType: reaction.reactionType
                        });
                    }
                }

                // Add the customer if it's not a group chat
                if (!chatSelection?.AllData?.isGroupChat) {
                    const customerIdHash = chatSelection?.AllData?.customerIdHash;
                    if (reaction.reacted.includes(customerIdHash) && !acc.has(customerIdHash)) {
                        acc.set(customerIdHash, {
                            firstName: chatSelection.AllData.firstName,
                            lastName: chatSelection.AllData.lastName,
                            fullName: chatSelection.AllData.name,
                            memberId: chatSelection.AllData.customerId,
                            memberIdHash: chatSelection.AllData.customerIdHash,
                            photo: chatSelection.AllData.profileImage,
                            cdnUrl: chatSelection.AllData.cdnUrl,
                            reactionType: reaction.reactionType
                        });
                    }
                }

                return acc;
            }, new Map());

            return Array.from(allReactedEmployees.values());
        }
    };
    return (
        <div className={`messagesArea flex-fill ${showLongPress ? "islongPress" : ""}`}>
            {!props.newChat ?
                <Fragment>
                    <div className={`messagesArea-header ${(isViewChanging && !isAndroidIOSMobile) ? " viewchange" : ""}`} style={{}}>
                        <div className="messagesArea-header_nameArea" onClick={(e) => {
                            if (isIpad || isIpadold || isTablet) {
                                CommonMethod.TriggerClick(".addMemberModal .btn-close");
                                if (rightsidePenal.current?.className && !document.querySelector(".messagesArea-header_nameArea .mobile-contextmenu")?.contains(e.target) && window.matchMedia('(orientation: landscape)').matches) {
                                    if (rightsidePenal.current.className === "rightSlide rightSlide-close") {
                                        rightsidePenal.current.className = "rightSlide rightSlide-open";
                                    }
                                    if (!e.target.dataset.clickFromSigleDetail || e.target.dataset.clickFromSigleDetail === 'false') {
                                        CommonMethod.RenderRightSidePanel();
                                    }
                                }
                                if (isFromConnectApp && isAndroidIOSMobile === false) {
                                    CommonMethod.RightSidePanelSetHeight();
                                }
                            }
                        }}>
                            <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => { onClickBackButtonFromChatArea(); AddCommonMethod("MobileCallBackForBack", () => { console.log("Mobile CallBack For Back Method Clear") }); HandleHistory("back"); }}>
                                <i class="fa-solid fa-angle-left"></i>
                            </a>
                            <a className="mobileChatBack" href={false} onClick={() => ChatBoxCloseClick()}>
                                <i class="fa-regular fa-angle-left"></i>
                            </a>
                            <div className='messagesArea-header_avGroup' >
                                <div id="messagesAreaGroupDetail" onClick={() => { if (isFromConnectApp) { if (isAndroidIOSMobile) { window.DeleteChannelDetail(false, true) } CommonMethod.AddClass(".vConnectMainWrap.chatActive", "detailsActive"); CommonMethod.Actionclick('reSizeDetail'); HandleHistory('push', 'detailsActive'); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }); } }}>
                                    {!isCustomerChat && chatSelection.AllData.isGroupChat === false &&
                                        <Fragment>
                                            <Usericon URL={chatSelection.AllData.cdnUrl} Image={chatSelection.AllData.profileImage} FirstName={chatSelection.AllData.firstName} LastName={chatSelection.AllData.lastName} />
                                        </Fragment>
                                    }
                                    {chatSelection.AllData.isGroupChat === true &&
                                        <Groupicon data={chatSelection.AllData.members.filter((x) => x.isMemberRemovedFromGroup === false)} />
                                    }
                                    {isCustomerChat ?
                                        <div className="smallChatBox-middle_nameArea">
                                            {ReactBusinessImage !== "" && <div className="smallChatBox-middle_nameArea-imgWrap">
                                                <img src={ReactBusinessImage} alt="Business logo" />
                                            </div>}
                                            <div className="smallChatBox-middle_nameArea-nameWrap">
                                                {ReactBusinessName}
                                            </div>
                                        </div>

                                        :
                                        <div className="messagesArea-header_name">
                                            {chatSelection.AllData.name}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='messagesArea-header_moreVertical'>
                                {isFromConnectApp &&
                                    <MobileDetailsDropdown />
                                }
                            </div>

                        </div>

                        {!isCustomerChat && <div className="messagesArea-header_btnArea">
                            <div className="rightAreaSlideTrigger">
                                <BtnRightsidepenal />
                            </div>
                            {isFromConnectApp === false &&
                                <div className="extendButton minimize" onClick={() => ExpandMinimizeButtonClick(expandMinimizeShow === 0 ? 2 : expandMinimizeShow + 1)}>
                                    <i class="fa-solid fa-arrow-down-left-and-arrow-up-right-to-center"></i>
                                </div>
                            }
                        </div>}

                    </div>

                    {isCustomerChat ?
                        (Object.entries(messageConvertions).length === 0 && chatSelectionID !== "") && <StenCiledConvertions isMobile={isAndroidIOSMobile} />
                        :
                        Object.entries(messageConvertions).length === 0 && <StenCiledConvertions isMobile={isAndroidIOSMobile} />
                    }
                    <div ref={middleArea} onScroll={(e) => { handleScrollEvent(e) }} className={`messagesArea-middle ${isViewChanging ? "viewchange" : ""} ${AIControl ? " tackControlActive" : ""}`} style={{ display: isViewChanging ? "none" : "block" }}>
                        <div className='chat-middle-box'>
                            <Fragment>
                                {Object.entries(messageConvertions).map(([date, messages]) => (
                                    <div key={date}>
                                        <div className="inChatmsg">
                                            {messages.every(message => message.isDeleted === true) === true && isCustomerChat ? messages.filter(message => message.isMessageFromCustomer).length > 0 && DateFormateFomChat(date, false, countryID) : messages.every(message => message.isDeleted === true && message.isMessageFromCustomer) ? <></> : isCustomerChat && messages.every(message => message.isNote) === true ? <></> : DateFormateFomChat(date, false, countryID)}
                                        </div>
                                        {messages.map((message, index) => (
                                            <Fragment>
                                                {message.isGroupUpdateMessage ?
                                                    <div className="inChatmsg personBlockText">
                                                        {message.message}
                                                    </div>
                                                    :
                                                    <Fragment>
                                                        {(LeftControlNames.Customer === LeftOption ? isCustomerChat ? message.isMessageFromCustomer === false : message.isMessageFromCustomer === true : message.sentByHash !== props.LoginUserIdHash) ?
                                                            <Fragment>
                                                                {!isCustomerChat && message.isDeleted === false &&
                                                                    <div className="recived messagesBox">
                                                                        <div className="avatar avatar-group avatar-sm" data-avatar="1">
                                                                            <div className="avatar-group-img-wrap" data-id={message.sentByHash}>
                                                                                {message.senderPhoto !== undefined && message.senderPhoto !== null && message.senderPhoto !== "" ?
                                                                                    <img alt='avtar' className="avatar-group-member" src={message.senderCdnURL + 'Original/' + message.senderPhoto} />
                                                                                    : <div> {utils.setLetter(message.senderFirstName || "", message.senderLastName || "")}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className={message.attachment && message?.attachment.length > 1 ? "messagesBox-block multipleVideoWrap" : "messagesBox-block"}>
                                                                            <div className="name">
                                                                                {message.senderFirstName + " " + message.senderLastName}
                                                                            </div>
                                                                            <div className='reactionMessageMainBlock'>
                                                                                <div className='reactionMessageWrap'
                                                                                    onClick={(e) => {
                                                                                        if (isFromConnectApp && e.target.tagName !== 'IMG' && e.target.tagName !== 'svg' && e.target.tagName !== 'path' && e.target.className !== 'firstPlay') {
                                                                                            if (e.target.className.includes('mobileURlLink')) {
                                                                                                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        if (!isAndroidIOSMobile && showEditEditor === null) {
                                                                                            setshowReactionAction(message);
                                                                                            // handleMouseEnter(message.reactions)
                                                                                        }
                                                                                    }}
                                                                                    onTouchStart={(e) => {
                                                                                        if (isFromConnectApp) {
                                                                                            handleTouchStart(e)
                                                                                            setHoldFuctiontimer(setTimeout(() => {
                                                                                                // Call function one for touch-and-hold action
                                                                                                if (message.sentByHash !== LoginUserIdHash && showReactionAction == null) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowReactionAction(message);
                                                                                                        CommonMethod.RemoveSelectionForMobile(isIOSMobile);
                                                                                                        if (window.location.href.includes("employeedetailsActive") && isAndroidIOSTabIpad()) {
                                                                                                            CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails();
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                setHoldFuctiontimer(null);
                                                                                            }, 250));
                                                                                        }
                                                                                    }}
                                                                                    onTouchEnd={(e) => {
                                                                                        if (isFromConnectApp) {
                                                                                            handleTouchEnd(e, index, message, messages)
                                                                                            clearTimeout(holdFuctiontimer);
                                                                                            if (holdFuctiontimer) {
                                                                                                if (showReactionAction === null && message.sentByHash !== LoginUserIdHash) {
                                                                                                    setshowReactionAction(message);
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setshowCopyDownloadLink(null);
                                                                                        setshowReactionAction(null);
                                                                                        setShowLongPress(false);
                                                                                        // setShowPicker(false)
                                                                                        // handleMouseLeave();
                                                                                    }}
                                                                                >

                                                                                    <div className={message.isNote ? "messages message-note-block" : "messages"}
                                                                                        id={message.messageId}
                                                                                    >


                                                                                        {showCopyDownloadLink !== null &&
                                                                                            <div className='editControlWrap copyDownlodDropdown'>
                                                                                                <a href={false}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        handleDownload(e, showCopyDownloadLink.url, showCopyDownloadLink.name)
                                                                                                        setTimeout(() => {
                                                                                                            setshowEditDeleteAction(null);
                                                                                                            setshowCopyDownloadLink(null);
                                                                                                            setShowLongPress(false);
                                                                                                            setshowReactionAction(null);
                                                                                                        }, 10);
                                                                                                    }}
                                                                                                    onTouchEnd={(e) => {
                                                                                                        if (e.cancelable) {
                                                                                                            e.target.click && e.target.click()
                                                                                                        }
                                                                                                    }}
                                                                                                    className='editControl'>
                                                                                                    <i class="fa-solid fa-download"></i> <span className='editControlText'>Download</span>
                                                                                                </a>

                                                                                            </div>
                                                                                        }
                                                                                        {showEditEditor !== null && showEditEditor.messageId === message.messageId ?
                                                                                            (<EditInput {...props} ckeditortype={2} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} messageDetails={message} EditDeleteMessage={EditDeleteMessage} expandMinimizeShow={expandMinimizeShow} isLastMessageIndex={index === messages.length - 1 ? true : false} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />)
                                                                                            :
                                                                                            ShowDesignMessage(message.message, message)
                                                                                        }
                                                                                        {showMentionSymbol(message.message) && <div className='mention-teg'>@</div>}
                                                                                    </div>
                                                                                    {message.reactions && message.reactions.length > 0 && (
                                                                                        <div className='reactedWrap'>
                                                                                            <Reacted reaction={message.reactions} message={message} />
                                                                                        </div>
                                                                                    )}

                                                                                    <div id={`editControlmainWrap_${message.messageId}`} className='editControlmainWrap' onTouchStart={(e) => { (e?.target?.className || "").includes("editControlmainWrap") && isFromConnectApp && handleOutsideClick(e); }} onClick={(e) => { isFromConnectApp && handleOutsideClick(e); }}>
                                                                                        {MemberIsexists === false && chatSelection.AllData.isLoggedInUserRemovedFromGroup === false && chatSelection.IsBlocked === false && showReactionAction?.messageId === message.messageId && ((isAndroidIOSMobile === false && !isAndroidIOSTabIpad()) || showLongPress || (isAndroidIOSTabIpad() === true && isFromConnectApp === false)) && <div className='editControlWrap'>
                                                                                            <div className='editControl-primary'>
                                                                                                {[
                                                                                                    { reactionType: 'Like', unified: '1f44d', reaction: '👍' },
                                                                                                    { reactionType: 'Heart', unified: '2764-fe0f', reaction: '❤️' },
                                                                                                    { reactionType: 'Face with Tears of Joy', unified: '1f602', reaction: '😂' },
                                                                                                    { reactionType: 'Face with Open Mouth', unified: '1f62e', reaction: '😮' },
                                                                                                    { reactionType: 'Sad but Relieved Face', unified: '1f625', reaction: '😥' },
                                                                                                ].map(({ reactionType, unified, reaction }) => {
                                                                                                    const isSelected = hasUserReacted(message, unified, chatSelection.AllData.userId);
                                                                                                    return (
                                                                                                        <a
                                                                                                            key={reaction}
                                                                                                            className={`editControl ${isSelected ? 'selected' : ''}`}
                                                                                                            onClick={() => { EditDeleteMessage(message, 6, index === messages.length - 1, { reactionType, unified, reaction, }); isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, ""); }}
                                                                                                            onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && e.target.click() }}
                                                                                                            href={false}
                                                                                                        >
                                                                                                            {reaction}
                                                                                                        </a>
                                                                                                    );
                                                                                                })}
                                                                                                <ReactionDropDown message={message} iconId={message.messageId} data={data} handleEmojiSelect={(e) => handleEmojiSelect(message, index, messages, e)}>

                                                                                                </ReactionDropDown>

                                                                                            </div>
                                                                                            <div className='editControl-secondary'>
                                                                                                {isFromConnectApp &&
                                                                                                    <a className='editControl editMobile' href={false}
                                                                                                        onTouchStart={(e) => {
                                                                                                            e.stopPropagation()
                                                                                                            handleCopyMessageText(e, message.message);

                                                                                                        }}
                                                                                                        onTouchEnd={(e) => {
                                                                                                            if (e.cancelable && isIOSMobile) {
                                                                                                                e.target.click();
                                                                                                            }
                                                                                                        }}

                                                                                                    >
                                                                                                        <i className="fa-solid fa-copy"></i>
                                                                                                        <span className='editControl-secondary_text editControlText'>
                                                                                                            {message.isNote ? "Copy Note" : "Copy Text"}
                                                                                                        </span>
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            {chatSelection.IsFromGroup === true && isAndroidIOSMobile &&
                                                                                                <div className='editControl-secondary'>
                                                                                                    <a className='editControl editMobile' href={false} onClick={(e) => { e.stopPropagation(); EditDeleteMessage(message, 7); }} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }} >
                                                                                                        <i class="fa-regular fa-comment-dots"></i><span className='editControl-secondary_text editControlText'>Message Privately</span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="time">{ConvertUtcToLocal(message.messageDate)}</div>

                                                                        </div>
                                                                    </div>
                                                                }
                                                                {isCustomerChat && message.isNote === false && message.isDeleted === false && message.isVcard === false &&
                                                                    <div className="recived messagesBox">
                                                                        <div className={message.attachment && message?.attachment.length > 1 ? "messagesBox-block multipleVideoWrap" : "messagesBox-block "}>
                                                                            <div className="name">
                                                                                {ReactBusinessName}
                                                                            </div>
                                                                            <div className='reactionMessageMainBlock'>
                                                                                <div className='reactionMessageWrap'
                                                                                    onClick={(e) => {
                                                                                        if (isFromConnectApp && e.target.tagName !== 'IMG' && e.target.tagName !== 'svg' && e.target.tagName !== 'path' && e.target.className !== 'firstPlay') {
                                                                                            if (e.target.className.includes('mobileURlLink')) {
                                                                                                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                            }
                                                                                        }
                                                                                        if (isCustomerChat && showEditEditor === null && isFromConnectApp === false) {
                                                                                            setshowReactionAction(message);
                                                                                        }
                                                                                    }}

                                                                                    onMouseEnter={() => {
                                                                                        if (!isAndroidIOSMobile && showEditEditor === null) {
                                                                                            setshowReactionAction(message);
                                                                                        }
                                                                                    }}
                                                                                    onTouchStart={(e) => {
                                                                                        if (isFromConnectApp) {
                                                                                            handleTouchStart(e)
                                                                                            setHoldFuctiontimer(setTimeout(() => {
                                                                                                if (message.sentByHash !== LoginUserIdHash && showReactionAction == null) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowReactionAction(message);
                                                                                                        CommonMethod.RemoveSelectionForMobile(isIOSMobile);
                                                                                                        if (window.location.href.includes("employeedetailsActive") && isAndroidIOSTabIpad()) {
                                                                                                            CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails();
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                setHoldFuctiontimer(null);
                                                                                            }, 250));
                                                                                        }
                                                                                    }}
                                                                                    onTouchEnd={(e) => {
                                                                                        if (isFromConnectApp) {
                                                                                            handleTouchEnd(e, index, message, messages)
                                                                                            clearTimeout(holdFuctiontimer);
                                                                                            if (holdFuctiontimer) {
                                                                                                if (showReactionAction === null && message.sentByHash !== LoginUserIdHash) {
                                                                                                    setshowReactionAction(message);
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setshowCopyDownloadLink(null);
                                                                                        setshowReactionAction(null);
                                                                                        setShowLongPress(false);
                                                                                    }}>
                                                                                    <div className={message.isNote ? "messages message-note-block" : "messages"}
                                                                                        id={message.messageId}>
                                                                                        {showEditEditor !== null && showEditEditor.messageId === message.messageId ?
                                                                                            (<EditInput {...props} ckeditortype={2} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} messageDetails={message} EditDeleteMessage={EditDeleteMessage} expandMinimizeShow={expandMinimizeShow} isLastMessageIndex={index === messages.length - 1 ? true : false} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />)
                                                                                            : ShowDesignMessage(message.message, message)}
                                                                                    </div>
                                                                                    {message.reactions && message.reactions.length > 0 && (
                                                                                        <div className='reactedWrap'>
                                                                                            <Reacted reaction={message.reactions} message={message} />
                                                                                        </div>
                                                                                    )}
                                                                                    <div id={`editControlmainWrap_${message.messageId}`} className={'editControlmainWrap'} onTouchStart={() => { isFromConnectApp && handleOutsideClick(); }} onClick={() => { isFromConnectApp && handleOutsideClick(); }}>
                                                                                        {MemberIsexists === false && chatSelection.AllData.isLoggedInUserRemovedFromGroup === false && chatSelection.IsBlocked === false && showReactionAction?.messageId === message.messageId && ((isAndroidIOSMobile === false && !isAndroidIOSTabIpad()) || showLongPress || (isCustomerChat)) && <div className='editControlWrap'>
                                                                                            <div className='editControl-primary'>
                                                                                                {[
                                                                                                    { reactionType: 'Like', unified: '1f44d', reaction: '👍' },
                                                                                                    { reactionType: 'Heart', unified: '2764-fe0f', reaction: '❤️' },
                                                                                                    { reactionType: 'Face with Tears of Joy', unified: '1f602', reaction: '😂' },
                                                                                                    { reactionType: 'Face with Open Mouth', unified: '1f62e', reaction: '😮' },
                                                                                                    { reactionType: 'Sad but Relieved Face', unified: '1f625', reaction: '😥' },
                                                                                                ].map(({ reactionType, unified, reaction }) => {
                                                                                                    const isSelected = hasUserReacted(message, unified, chatSelection.AllData.userId);
                                                                                                    return (
                                                                                                        <a
                                                                                                            key={reaction}
                                                                                                            className={`editControl ${isSelected ? 'selected' : ''}`}
                                                                                                            onClick={() => { EditDeleteMessage(message, 6, index === messages.length - 1, { reactionType, unified, reaction, }); isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, ""); }}
                                                                                                            onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && e.target.click() }}
                                                                                                            href={false}
                                                                                                        >
                                                                                                            {reaction}
                                                                                                        </a>
                                                                                                    );
                                                                                                })}

                                                                                                <ReactionDropDown message={message} iconId={message.messageId} data={data} handleEmojiSelect={(e) => handleEmojiSelect(message, index, messages, e)}>

                                                                                                </ReactionDropDown>
                                                                                            </div>
                                                                                        </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="time">{ConvertUtcToLocal(message.messageDate)}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {message.isDeleted === false &&
                                                                    <div className={(`sent messagesBox${showEditEditor !== null && showEditEditor.messageId === message.messageId ? " messagesEditWrap" : ""} `) + (message.isAIMessage ? " aiMessagesBox" : "")}>
                                                                        <div className={message.attachment && message?.attachment.length > 1 ? "messagesBox-block multipleVideoWrap" : "messagesBox-block "}>
                                                                            {!isCustomerChat &&
                                                                                <div className="name">
                                                                                    {message.isAIMessage ? message.fullName : message.senderFirstName + " " + message.senderLastName}{isMultilocationBusiness ? " - " + ReactBusinessName : ""}
                                                                                </div>
                                                                            }
                                                                            <div className='reactionMessageMainBlock'>
                                                                                <div className='reactionMessageWrap'>
                                                                                    <div
                                                                                        id={message.messageId}
                                                                                        className={message.isNote ? "messages message-note-block" : message.isVcard !== undefined && message.isVcard !== null && message.isVcard !== "" && message.isVcard === true ? "messages vcCard" : "messages"}
                                                                                        onClick={(e) => {
                                                                                            if (isFromConnectApp && !['IMG', 'VIDEO', 'svg', 'path', 'CANVAS'].includes(e?.target?.tagName)) {
                                                                                                if (typeof e.target.className === 'string' && e.target.className.includes('mobileURlLink')) {
                                                                                                    utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                                } else if (['firstPlay', "videoDropdown mobile-contextmenu show dropdown", "videoControl videoControl-playPush"].includes(e.target.className)) {
                                                                                                    setshowEditDeleteAction(null);
                                                                                                    setshowCopyDownloadLink(null);
                                                                                                    setShowLongPress(false);
                                                                                                    setshowReactionAction(null);
                                                                                                } else if (message.sentByHash === LoginUserIdHash) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowEditDeleteAction(message.messageId);
                                                                                                    }
                                                                                                }
                                                                                            } else if (!isFromConnectApp) {
                                                                                                if (message.sentByHash === LoginUserIdHash) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowEditDeleteAction(message.messageId);
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}


                                                                                        onTouchStart={(e) => {
                                                                                            if (isFromConnectApp && !['svg', 'path', 'CANVAS'].includes(e?.target?.tagName) && !['firstPlay', "videoDropdown mobile-contextmenu show dropdown", "videoControl videoControl-playPush"].includes(e.target.className)) {
                                                                                                if (message.isVcard === false) {
                                                                                                    handleTouchStart(e)
                                                                                                }

                                                                                                setHoldFuctiontimer(setTimeout(() => {
                                                                                                    // Call function one for touch-and-hold action
                                                                                                    if (message.sentByHash === LoginUserIdHash) {
                                                                                                        if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                            setshowEditDeleteAction(message.messageId);
                                                                                                            CommonMethod.RemoveSelectionForMobile(isIOSMobile);
                                                                                                            if (window.location.href.includes("employeedetailsActive") && isAndroidIOSTabIpad()) {
                                                                                                                CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails();
                                                                                                            }
                                                                                                        }
                                                                                                    } else {
                                                                                                        setshowReactionAction(message);
                                                                                                        CommonMethod.RemoveSelectionForMobile(isIOSMobile);
                                                                                                    }
                                                                                                    setHoldFuctiontimer(null); // Reset timer after touch-and-hold
                                                                                                }, 250));
                                                                                            }
                                                                                        }}
                                                                                        onTouchEnd={(e) => {
                                                                                            if (isFromConnectApp && !['svg', 'path', 'CANVAS'].includes(e?.target?.tagName) && !['firstPlay', "videoDropdown mobile-contextmenu show dropdown", "videoControl videoControl-playPush"].includes(e.target.className)) {
                                                                                                handleTouchEnd(e, index, message, messages)
                                                                                                clearTimeout(holdFuctiontimer);
                                                                                                if (holdFuctiontimer) {
                                                                                                    // Call function two for tap action if timer was not cleared
                                                                                                    if (typeof e.target.className === 'string' && e.target.className.includes('mobileURlLink')) {
                                                                                                        utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                                    }
                                                                                                    else if (e.target.tagName === 'IMG') { console.log("Image"); }
                                                                                                    else if (message.sentByHash === LoginUserIdHash) {
                                                                                                        if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                            setshowEditDeleteAction(message.messageId);

                                                                                                        }
                                                                                                    }
                                                                                                    else if (showReactionAction === null && message.sentByHash !== LoginUserIdHash) {
                                                                                                        setshowReactionAction(message);
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onTouchCancel={() => {
                                                                                            clearTimeout(holdFuctiontimer)
                                                                                            setHoldFuctiontimer(null);
                                                                                        }}

                                                                                        onMouseEnter={() => {
                                                                                            if (isAndroidIOSMobile == false) {
                                                                                                if (message.sentByHash === LoginUserIdHash) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowEditDeleteAction(message.messageId);
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    setshowReactionAction(message);
                                                                                                }
                                                                                            }
                                                                                            if (isCustomerChat) {
                                                                                                if (message.sentByHash === LoginUserIdHash) {
                                                                                                    if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                        setshowEditDeleteAction(message.messageId);
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    setshowReactionAction(message);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            setshowEditDeleteAction(null);
                                                                                            setshowCopyDownloadLink(null);
                                                                                            setShowLongPress(false);
                                                                                            setshowReactionAction(null);
                                                                                        }}>
                                                                                        <div id={`editControlmainWrap_${message.messageId}`} className='editControlmainWrap' onTouchStart={(e) => { (e?.target?.className || "").includes("editControlmainWrap") && isFromConnectApp && handleOutsideClick(e); }} onClick={(e) => { isFromConnectApp && handleOutsideClick(e); }}>
                                                                                            {MemberIsexists === false && chatSelection.AllData.isLoggedInUserRemovedFromGroup === false && chatSelection.IsBlocked === false && message.isVcard === false && (showEditDeleteAction === message.messageId || showReactionAction?.messageId === message.messageId) && ((isAndroidIOSMobile === false && !isAndroidIOSTabIpad()) || showLongPress || (isCustomerChat) || (isAndroidIOSTabIpad() === true && isFromConnectApp === false)) &&
                                                                                                <div className='editControlWrap'>
                                                                                                    <div className={message.isAIMessage === true ? 'editControl-primary d-none' : 'editControl-primary'}>
                                                                                                        {[
                                                                                                            { reactionType: 'Like', unified: '1f44d', reaction: '👍' },
                                                                                                            { reactionType: 'Heart', unified: '2764-fe0f', reaction: '❤️' },
                                                                                                            { reactionType: 'Face with Tears of Joy', unified: '1f602', reaction: '😂' },
                                                                                                            { reactionType: 'Face with Open Mouth', unified: '1f62e', reaction: '😮' },
                                                                                                            { reactionType: 'Sad but Relieved Face', unified: '1f625', reaction: '😥' },
                                                                                                        ].map(({ reactionType, unified, reaction }) => {
                                                                                                            const isSelected = hasUserReacted(message, unified, chatSelection.AllData.userId);
                                                                                                            return (
                                                                                                                <a
                                                                                                                    key={reaction}
                                                                                                                    className={`editControl ${isSelected ? 'selected' : ''}`}
                                                                                                                    onClick={() => { EditDeleteMessage(message, 6, index === messages.length - 1, { reactionType, unified, reaction, }); isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.DoHeptic, ""); }}
                                                                                                                    onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && e.target.click() }}
                                                                                                                    href={false}
                                                                                                                >
                                                                                                                    {reaction}
                                                                                                                </a>
                                                                                                            );
                                                                                                        })}
                                                                                                        <ReactionDropDown message={message} iconId={message.messageId} data={data} handleEmojiSelect={(e) => handleEmojiSelect(message, index, messages, e)}>

                                                                                                        </ReactionDropDown>
                                                                                                    </div>
                                                                                                    {(showEditDeleteAction === message.messageId || message.isAIMessage === true) &&
                                                                                                        <div className='editControl-secondary'>
                                                                                                            {message.isAIMessage === true ?
                                                                                                                <>
                                                                                                                    <a className={message.isDislikeMessage ? 'editControl aiResponceWrap active' : 'editControl aiResponceWrap'} href={false} onClick={() => EditDeleteMessage(message, 8, index === messages.length - 1, null, message.isAIMessage && message.isDislikeMessage)} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }}>
                                                                                                                        <span className='aiResponceBlock'>
                                                                                                                            <span className='aiResponceBlock-iconWrap'>
                                                                                                                                <i class="fa-regular fa-thumbs-down"></i>
                                                                                                                            </span>
                                                                                                                            <span className='editControl-secondary_text editControlText aiResponceBlock-text'>{message.isAIMessage && message.isDislikeMessage ? "Undo Response" : "Dislike Response"}</span>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    {isFromConnectApp &&
                                                                                                                        <a className='editControl editMobile' href={false}
                                                                                                                            onTouchStart={(e) => {
                                                                                                                                e.stopPropagation()
                                                                                                                                handleCopyMessageText(e, message.message);

                                                                                                                            }}
                                                                                                                            onTouchEnd={(e) => {
                                                                                                                                if (e.cancelable && isIOSMobile) {
                                                                                                                                    e.target.click();
                                                                                                                                }
                                                                                                                            }}

                                                                                                                        >
                                                                                                                            <i className="fa-solid fa-copy"></i>
                                                                                                                            <span className='editControl-secondary_text editControlText'>
                                                                                                                                {message.isNote ? "Copy Note" : "Copy Text"}
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    }
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <a className='editControl editMobile' href={false} onClick={(e) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        EditDeleteMessage(message, 1);
                                                                                                                        setTimeout(() => {
                                                                                                                            setshowCopyDownloadLink(null);
                                                                                                                            setshowEditDeleteAction(null);
                                                                                                                        }, 5);
                                                                                                                    }} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }} >
                                                                                                                        <i class="fa-regular fa-pen"></i> <span className='editControl-secondary_text editControlText'>{message.isNote ? "Edit Note" : "Edit Text"}</span>
                                                                                                                    </a>
                                                                                                                    <a className='editControl deleteMobile' href={false} onClick={() => EditDeleteMessage(message, 2, index === messages.length - 1 ? true : false)} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }}>
                                                                                                                        <i class="fa-regular fa-trash"></i> <span className='editControl-secondary_text editControlText'>{message.isNote ? "Delete Note" : "Delete Text"}</span>
                                                                                                                    </a>
                                                                                                                    {isFromConnectApp &&
                                                                                                                        <a className='editControl editMobile' href={false}
                                                                                                                            onTouchStart={(e) => {
                                                                                                                                e.stopPropagation()
                                                                                                                                handleCopyMessageText(e, message.message);

                                                                                                                            }}
                                                                                                                            onTouchEnd={(e) => {
                                                                                                                                if (e.cancelable && isIOSMobile) {
                                                                                                                                    e.target.click();
                                                                                                                                }
                                                                                                                            }}

                                                                                                                        >
                                                                                                                            <i className="fa-solid fa-copy"></i>
                                                                                                                            <span className='editControl-secondary_text editControlText'>
                                                                                                                                {message.isNote ? "Copy Note" : "Copy Text"}
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {showCopyDownloadLink !== null &&
                                                                                            <div className='editControlWrap copyDownlodDropdown'>
                                                                                                {/* <a href={false}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        handleCopyLink(e, showCopyDownloadLink.url)
                                                                                                        setTimeout(() => {
                                                                                                            setshowEditDeleteAction(null);
                                                                                                            setshowCopyDownloadLink(null);
                                                                                                        }, 10);
                                                                                                    }}
                                                                                                    onTouchEnd={(e) => {
                                                                                                        if (e.cancelable) {
                                                                                                            e.target.click && e.target.click()
                                                                                                        }
                                                                                                    }}
                                                                                                    className='editControl'>
                                                                                                    <i class="fa-solid fa-link-simple"></i> <span className='editControlText'>Copy Link</span>
                                                                                                </a> */}
                                                                                                <a href={false}
                                                                                                    onClick={(e) => {
                                                                                                        // e.stopPropagation();
                                                                                                        handleDownload(e, showCopyDownloadLink.url, showCopyDownloadLink.name)
                                                                                                        setTimeout(() => {
                                                                                                            setshowEditDeleteAction(null);
                                                                                                            setshowCopyDownloadLink(null);
                                                                                                            setShowLongPress(false);
                                                                                                            setshowReactionAction(null);
                                                                                                        }, 10);
                                                                                                    }}
                                                                                                    onTouchEnd={(e) => {
                                                                                                        if (e.cancelable) {
                                                                                                            e.target.click && e.target.click()
                                                                                                        }
                                                                                                    }}
                                                                                                    className='editControl'>
                                                                                                    <i class="fa-solid fa-download"></i> <span className='editControlText'>Download</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        }

                                                                                        {showEditEditor !== null && showEditEditor.messageId === message.messageId ?
                                                                                            (<EditInput {...props} messageId={message.messageId} ckeditortype={2} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} messageDetails={message} EditDeleteMessage={EditDeleteMessage} expandMinimizeShow={expandMinimizeShow} isLastMessageIndex={index === messages.length - 1 ? true : false} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />)
                                                                                            : ShowDesignMessage(message.message, message)}


                                                                                    </div>
                                                                                    {message.reactions && message.reactions.length > 0 && (
                                                                                        <div className='reactedWrap'>
                                                                                            <Reacted reaction={message.reactions} message={message} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {message.isAIMessage && message.isDislikeMessage ?
                                                                                <div className='messageBottomWrap'>
                                                                                    <div className="feedbackSubmited">
                                                                                        AI feedback submitted.
                                                                                    </div>
                                                                                    <div className="time">
                                                                                        {ConvertUtcToLocal(message.messageDate)}
                                                                                        {("Customers" === ActiveLeftOption && !isCustomerChat) ? message.isSMS ? " via SMS" : " via App" : ""}
                                                                                        <span class="chechIcon"></span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="time">
                                                                                    {ConvertUtcToLocal(message.messageDate)}
                                                                                    {("Customers" === ActiveLeftOption && !isCustomerChat) ? message.isSMS ? " via SMS" : " via App" : ""}
                                                                                    <span class="chechIcon"></span>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                        {!isCustomerChat &&
                                                                            <div className="avatar avatar-sm aiAvatarWrap" data-avatar="1">
                                                                                {message.isAIMessage ? <div class="aiAvatar"><i class="fa-solid fa-sparkles" aria-hidden="true"></i></div> :
                                                                                    <div className="avatar-group-img-wrap">
                                                                                        {message.sentByHash === LoginUserIdHash ?
                                                                                            ReactProfileImage !== undefined && ReactProfileImage !== null && ReactProfileImage !== "" ?
                                                                                                <img alt='avtar' src={ReactCdnUrl + 'Original/' + ReactProfileImage} className='avatar-group-member' />
                                                                                                : <div> {utils.setLetter(ReactFirstName || "", ReactLastName || "")}</div>

                                                                                            :
                                                                                            message.senderPhoto !== undefined && message.senderPhoto !== null && message.senderPhoto !== "" ?
                                                                                                <img alt='avtar' className="avatar-group-member" src={message.senderCdnURL + 'Original/' + message.senderPhoto} />
                                                                                                : <div> {utils.setLetter(message.senderFirstName || "", message.senderLastName || "")}</div>

                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>}
                                                                    </div>
                                                                }
                                                                {message.isDeleted === true && message.sentByHash === LoginUserIdHash &&
                                                                    <div className="chat-deletedWrap">
                                                                        <div className="chat-deleted">
                                                                            This message has been deleted. <a className='chat-deleted_undo' href={false} onClick={() => EditDeleteMessage(message, 5, index === messages.length - 1 ? true : false)}>Undo</a>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                        }
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        )
                                        )}
                                    </div>
                                ))}
                                <div id='root_reaction_emojiPicker' className='emojiPickerReactionFormobile' ></div>
                            </Fragment>
                        </div>


                    </div >

                    {!isCustomerChat && <div className="smallChatBox-footer">
                        <nav className="smallChatBox-footer_actions">
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/attachmentBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/gifBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/urlBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/noteIcon.svg" alt="" />
                            </a>
                        </nav>

                        <div className="smallChatBox-footer_fieldWrap">
                            <input type="text" className="inputField" placeholder="Enter a message." />
                            <a href={false} className="smileIcon">
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/smileBlue.svg" alt="" />
                            </a>
                        </div>
                    </div>}

                    {
                        (!isCustomerChat ? (expandMinimizeShow === 0 || chatAreaShow) : chatAreaShow) &&
                        <div className="messagesArea-footer">
                            {/* <div className='takecontrol-wrapper'>
                                <div className='takecontrol-border'>
                                <div className='takecontrol-banner'> <div className='btn-text-row'> <span className='vg-ai-icon'>&#xf890;</span> <div className='ai-chatting-text desktop-text-hide'>Vagaro AI is chatting. You can always take control.</div> <div className='pro-text-hide-content dektop-text-hide'>Connect AI is chatting.</div></div> <button type="button" class="btn btn btn-secondary btn-takecontrol"><span>Take Control</span></button> </div> </div>
                            </div> */}
                            <div className={(isAndroidIOSTabIpad() && isCustomerChat === false) ? 'typeChatWrap' : isCustomerChat ? (chatAreaShow ? "typeChatWrap collapsibleChat" : "typeChatWrap") : `typeChatWrap${chatAreaShow && expandMinimizeShow !== 0 ? " collapsibleChat" : ""} `}>
                                <UserTyping UserId={props.LoginUserIdHash} ConversationId={chatSelection.ConversationId} isCustomerChat={isCustomerChat} ChatOption={LeftOption} middleArea={middleArea} />
                                {/* Use This class for mobile devices
                        <div className={`typeChatWrap${isCustomerChat || chatAreaShow ? " mobileEditer active" : ""} `}>
                    */}
                                <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />


                            </div>
                        </div>
                    }
                    {
                        imageGallery && !isFromConnectApp &&
                        <ImageGallery {...props} srcValues={galleryImages} setImageGalarry={setImageGallery} isCustomerChat={isCustomerChat} expandMinimizeShow={expandMinimizeShow} />
                    }
                </Fragment >
                :
                LeftOption === LeftControlNames.Employee ?
                    <div className="">
                        <div className='employeeSelectWrap'>
                            <MultiGroupSelectEmployee
                                className=""
                                classNamePrefix="vg-select"
                                aria-label="Default select example"
                                options={props.ServiceproviderasperMultilocation}
                                onChange={(serviceprovider, selectednew) => {
                                    props.serviceProvider(serviceprovider, selectednew);
                                }}
                                onChangeSpName={(SpName) => {
                                    props.setserviceproviderName(SpName);
                                }}
                                value={props.providervalue}
                                isMulti
                                ControlID="ddlEmployee"
                                searchInputPlaceholder={"Search"}
                                LoginUserIdHash={LoginUserIdHash}
                                placeholder="Select an teammates"
                                isandroidiospro={isAndroidIOSMobile}
                                isFromConnectApp={isFromConnectApp}
                                isNewChat={isNewChat}
                            />

                        </div>
                        <div className='newchatEditerWrap'>
                            <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />
                        </div>
                    </div> :
                    <div className="">
                        <div className='employeeSelectWrap'>
                            <MerchantsSingleSelecttwo
                                ControlID="Customer"
                                placeholder="Select a customer"
                                MerchantID={merchantId}
                                OnChangeCustomer={props.OnChangeCustomer}
                                MultiCustomersId={"ddlSelectCustomer11"}
                                ManageCustomerViewRights={ManageCustomerViewRights}
                                isandroidiospro={isAndroidIOSMobile}
                                isNewChat={isNewChat}
                            />

                        </div>
                        <div className='newchatEditerWrap'>
                            <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} customerData={customerData} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />
                        </div>
                    </div>
            }
        </div >

        /* This is for membership and gift card design Do not remove this
    <div className="sent messagesBox">
     
    <div className="messagesBox-block">
    <div className="name">
    xyg
    </div>
    <div className="messages vcCard">
    <div className='vcCard-title'>
        <div className='vcCard-title_icon'>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5625 2.75C16.5879 2.75 17.4375 3.59961 17.4375 4.625V14C17.4375 15.0547 16.5879 15.875 15.5625 15.875H2.4375C1.38281 15.875 0.5625 15.0547 0.5625 14V4.625C0.5625 3.59961 1.38281 2.75 2.4375 2.75H6.65625V1.8125C6.65625 1.31445 7.06641 0.875 7.59375 0.875H10.4062C10.9043 0.875 11.3438 1.31445 11.3438 1.8125V2.75H15.5625ZM8.0625 2.28125V4.15625H9.9375V2.28125H8.0625ZM16.0312 14V4.625C16.0312 4.39062 15.7969 4.15625 15.5625 4.15625H11.3438V5.5625H6.65625V4.15625H2.4375C2.17383 4.15625 1.96875 4.39062 1.96875 4.625V14C1.96875 14.2637 2.17383 14.4688 2.4375 14.4688H5.71875C5.71875 14.4395 5.71875 14.4102 5.71875 14.3809V13.8242C5.71875 12.8867 6.59766 12.125 7.68164 12.125C8.00391 12.125 8.20898 12.3594 9 12.3594C9.76172 12.3594 9.99609 12.125 10.2891 12.125C11.373 12.125 12.2812 12.8867 12.2812 13.8242V14.3809C12.2812 14.4102 12.252 14.4395 12.252 14.4688H15.5625C15.7969 14.4688 16.0312 14.2637 16.0312 14ZM9 7.4375C10.0254 7.4375 10.875 8.28711 10.875 9.3125C10.875 10.3672 10.0254 11.1875 9 11.1875C7.94531 11.1875 7.125 10.3672 7.125 9.3125C7.125 8.28711 7.94531 7.4375 9 7.4375Z" fill="#333333"/>
            </svg>
        </div>
        <div className='vcCard-title_name'>Membershipe Name</div>
    </div>
    <a href="{false}" className='vcCard-button'>Shop for Membership</a>
    </div>
    <div className="time">
    {moment(message.messageDate).format("hh:mm A")}
    <span class="chechIcon"></span>
    </div>
    </div> 
     
    <div className="avatar avatar-sm" data-avatar="1">
    <div className="avatar-group-img-wrap">
    {ReactProfileImage !== undefined ?
        <img alt='avtar' src={ReactCdnUrl + 'Original/' + ReactProfileImage} className='avatar-group-member' />
        : <div> {utils.setLetter(ReactFirstName || "", ReactLastName || "")}</div>
    }
    </div>
    </div>
    </div>
    */
    );
}
const UserTyping = (props) => {
    const dispatch = useDispatch();
    const { isCustomerChat, ChatOption, middleArea } = props;
    const { chatSelection, ScrollisBottom, AIControl, setAIControl } = useChatContext();
    const RemoveUsers = useSelector((state) => state.TypingUser.Users);
    const TypingUser = useSelector((state) => selectTypingUsers(state, props));
    const [typingEmployee, setTypingEmployee] = useState([])
    const [typingCustomer, setTypingCustomer] = useState([])

    var ScrollBottom = ScrollisBottom.current;
    useEffect(() => {
        const intervalId = setInterval(() => {
            RemoveUsers.map(arr => {
                if (Date.now() - arr.LastUpdateTime >= 10000) {
                    dispatch(CheckTypingUser(arr))
                }
                return true;
            })
        }, 15000);

        return () => clearInterval(intervalId);
    }, [RemoveUsers, dispatch])

    useEffect(() => {
        if (TypingUser.length > 0 && ScrollBottom && middleArea.current) {
            middleArea.current.scrollTop += 100
        }
        if (!isCustomerChat) {
            if (ChatOption === LeftControlNames.Customer) {
                setTypingCustomer(TypingUser.filter(val => val.userId === chatSelection?.AllData?.customerIdHash))
                setTypingEmployee(TypingUser.filter(val => val.userId !== chatSelection?.AllData?.customerIdHash))
            }
            else {
                setTypingEmployee(TypingUser)
            }
        } else {
            setTypingEmployee(TypingUser)
        }
    }, [TypingUser])

    useEffect(() => {
        if (!isCustomerChat && ChatOption === LeftControlNames.Customer && AIControl === false && typingEmployee.some(user => user.AI === true)) {
            setAIControl(true)
        }
    }, [isCustomerChat, ChatOption, AIControl, setAIControl, typingEmployee]);

    const TypingText = (users) => {
        let Text = "";
        if (users.length > 4) {
            Text += users.length + " teammates are typing..."
        } else {
            users.map((arr, index) => {
                if (index === 0) {
                    Text += arr.fullName;
                } else {
                    if (index + 1 < users.length) {
                        Text += ", " + arr.fullName;
                    } else {
                        Text += " and " + arr.fullName;
                    }
                }
                return "";
            })
            if (users.length === 1) {
                Text += " is typing..."
            } else {
                Text += " are typing..."
            }
        }
        return Text;
    };

    const TypingWrap = ({ users }) => {
        return (
            <div className='isTypingWrap'>
                {!isCustomerChat &&
                    <div className="avatar avatar-group avatar-sm" data-avatar={users.length > 4 ? 4 : users.length}>
                        {users.map((a, index) => {
                            if (index < 3) {
                                return (
                                    <Fragment>
                                        <div className="avatar-group-img-wrap">
                                            {a.AI ?
                                                <div class="aiAvatar"><i class="fa-solid fa-sparkles" aria-hidden="true"></i></div>
                                                :
                                                <>
                                                    {(a.url !== undefined && a.url !== "" && a.url !== null) ?
                                                        <img alt='avtar' className="avatar-group-member" src={a.url} />
                                                        : <div> {utils.setLetter(a.fullName.split(" ")[0] || "", a.fullName.split(" ")[1] || "")}</div>
                                                    }
                                                </>}
                                        </div>
                                    </Fragment>
                                )
                            }
                        })}
                        {users.length > 3 &&
                            <div className="avatar-group-img-wrap">
                                <div className="avatar-group-img-wrap-text">
                                    +{users.length - 3}
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className='isTypingWrap-text'>
                    <div class="isTypingWrap-loader">
                        <span className='loaderDot loaderDot-1'></span>
                        <span className='loaderDot loaderDot-2'></span>
                        <span className='loaderDot loaderDot-3'></span>
                    </div>
                </div>
                {!isCustomerChat &&
                    <span class="isTypingWrap-text_personList">{TypingText(users)}</span>
                }
            </div>
        );
    };

    const HandleClass = useCallback(() => {
        var name = "";
        if (TypingUser.length > 0) {
            name += 'isTypingMain ';
        }
        if (TypingUser.some(d => d.AI === true)) {
            name += 'aiTypinginChat ';
        }
        return name
    }, [TypingUser]);
    return (
        <div className={HandleClass()}>
            {typingEmployee.length > 0 &&
                <TypingWrap users={typingEmployee} />
            }
            {typingCustomer.length > 0 &&
                <TypingWrap users={typingCustomer} />
            }
        </div>
    )
}

export default ChatArea;